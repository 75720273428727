import React, { forwardRef, useEffect, useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    ClickAwayListener,
    Dialog,
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Slide,
    SlideProps,
    Typography,
    useMediaQuery
} from '@mui/material';

// project imports
import Transitions from 'ui-component/extended/Transitions';

// assets
import TranslateTwoToneIcon from '@mui/icons-material/TranslateTwoTone';
import useConfig from 'hooks/useConfig';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { IconSettings, IconPencil } from '@tabler/icons';
import ConfigSiteForm from '../../../../views/pages/home/ConfigSiteForm';
import AddSiteForm from '../../../../views/pages/home/AddSiteForm';
import { FormikValues } from 'formik';
import axios from '../../../../utils/axios';
import { openSnackbar } from '../../../../store/slices/snackbar';
import { useDispatch } from '../../../../store';
import useSessionStorage from '../../../../hooks/useSessionStorage';
import { FormattedMessage, useIntl } from 'react-intl';
// ==============================|| LOCALIZATION ||============================== //

const Transition = forwardRef((props: SlideProps, ref) => <Slide direction="left" ref={ref} {...props} />);

const SiteConfigSection = () => {
    const theme = useTheme();
    const intl = useIntl();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = useState(false);
    const anchorRef = useRef<any>(null);
    const dispatch = useDispatch();
    const { borderRadius } = useConfig();

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const [routeState, setRouteState] = useSessionStorage('4seils-route', {
        site: '',
        name: '',
        picture: '',
        service: '',
        role: -1
    });

    const [modal, setModal] = useState<{ open: boolean; mode: string }>({
        open: false,
        mode: ''
    });

    const handleUpdateSite = async (siteID: string, update: FormikValues) => {
        try {
            await axios.post('/api/v1/' + routeState.service + '/site/' + siteID, update);
            const response = await axios.get('/api/v1/' + routeState.service + '/site/' + siteID);
            const site = response.data;

            setRouteState({
                ...routeState,
                site: site.id,
                name: site.name,
                picture: site.picture
            });
        } catch (error) {}

        // setRouteState({ service: modal.service, site: update.id, name: update.name, picture: update.picture });
        // navigate('/dashboard', { replace: false });
        handleModalClose();
    };

    const handleUpdateSiteConfig = async (update: FormikValues) => {
        console.log(update);
        try {
            await axios.post(`/api/v1/${routeState.service}/site/${routeState.site}/config`, update);
            // dispatch(removeEvent(id));
            handleModalClose();

            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({ id: 'success' }),
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'primary'
                    },
                    close: true
                })
            );
        } catch (err) {
            console.error(err);
            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({ id: 'failed' }),
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };

    const handleModalClose = () => {
        setModal({ open: false, mode: '' });
    };

    const handleEditClick = () => {
        setModal({ open: true, mode: 'edit' });
        setOpen(false);
    };

    const handleConfigClick = () => {
        setModal({ open: true, mode: 'config' });
        setOpen(false);
    };

    return (
        <>
            <Box
                sx={{
                    ml: 2,
                    mr: 2,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        border: '1px solid',
                        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[200],
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[200],
                        color: theme.palette.primary.dark,
                        transition: 'all .2s ease-in-out',
                        '&[aria-controls="menu-list-grow"],&:hover': {
                            borderColor: theme.palette.primary.main,
                            background: theme.palette.primary.main,
                            color: theme.palette.primary.light
                        }
                    }}
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    color="inherit"
                >
                    <MoreHorizOutlinedIcon
                        fontSize="small"
                        color="inherit"
                        sx={{
                            cursor: 'pointer',
                            // position: 'absolute',
                            // top: 10,
                            // left: 10,
                            '&:hover': {
                                color: theme.palette.primary.light
                            }
                        }}
                        aria-controls="menu-site-card"
                        aria-haspopup="true"
                    />
                    {/*{language !== 'en' && (*/}
                    {/*    <Typography variant="h5" sx={{ textTransform: 'uppercase' }} color="inherit">*/}
                    {/*        {language}*/}
                    {/*    </Typography>*/}
                    {/*)}*/}
                    {/*{language === 'en' && <TranslateTwoToneIcon sx={{ fontSize: '1.3rem' }} />}*/}
                </Avatar>
            </Box>

            <Popper
                placement={matchesXs ? 'bottom-start' : 'bottom'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [matchesXs ? 0 : 0, 20]
                        }
                    }
                ]}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions position={matchesXs ? 'top-left' : 'top'} in={open} {...TransitionProps}>
                            <Paper elevation={16}>
                                {open && (
                                    <List
                                        component="nav"
                                        sx={{
                                            width: '100%',
                                            maxWidth: 280,
                                            bgcolor: theme.palette.background.paper,
                                            borderRadius: `${borderRadius}px`,
                                            [theme.breakpoints.down('md')]: {
                                                maxWidth: 250
                                            }
                                        }}
                                    >
                                        <ListItemButton onClick={handleEditClick}>
                                            <ListItemIcon>
                                                <IconPencil stroke={1.5} size="1.3rem" />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="body2">
                                                        <FormattedMessage id="edit" />
                                                    </Typography>
                                                }
                                            />
                                        </ListItemButton>

                                        <ListItemButton onClick={handleConfigClick}>
                                            <ListItemIcon>
                                                <IconSettings stroke={1.5} size="1.3rem" />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="body2">
                                                        <FormattedMessage id="setting" />
                                                    </Typography>
                                                }
                                            />
                                        </ListItemButton>
                                    </List>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>

            <Dialog
                maxWidth="sm"
                fullWidth
                onClose={handleModalClose}
                open={modal.open}
                TransitionComponent={Transition}
                keepMounted
                sx={{
                    '&>div:nth-of-type(3)': {
                        justifyContent: 'flex-end',
                        '&>div': {
                            m: 0,
                            borderRadius: '0px',
                            maxWidth: 450,
                            maxHeight: '100%',
                            minHeight: '100%',
                            [theme.breakpoints.down('md')]: {
                                maxWidth: 'auto',
                                minWidth: 'auto'
                            }
                        }
                    }
                }}
            >
                {modal.mode === 'config' && modal.open && (
                    <ConfigSiteForm
                        onCancel={handleModalClose}
                        handleUpdate={handleUpdateSiteConfig}
                        siteId={routeState.site}
                        service={routeState.service}
                    />
                )}
                {modal.mode === 'edit' && modal.open && (
                    <AddSiteForm
                        onCancel={handleModalClose}
                        // handleDelete={handleDeleteSite}
                        handleUpdate={handleUpdateSite}
                        site={{
                            id: routeState.site,
                            name: routeState.name,
                            picture: routeState.picture
                        }}
                    />
                )}
            </Dialog>
        </>
    );
};

export default SiteConfigSection;
