// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Button,
    CardContent,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    TextField,
    Typography,
    Stack,
    Tabs,
    Tab,
    InputAdornment,
    Dialog,
    SlideProps,
    Slide,
    FormControl,
    Select,
    Tooltip
} from '@mui/material';

import { Howl, Howler } from 'howler';
import Fab from '@mui/material/Fab';
import { gridSpacing } from 'store/constant';
import QrCodeScannerTwoToneIcon from '@mui/icons-material/QrCodeScannerTwoTone';

// assets
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import axios from '../../../utils/axios';
import { useQuery } from 'react-query';
import { dispatch, useDispatch } from '../../../store';
import { FormattedMessage, useIntl } from 'react-intl';
import useSessionStorage from '../../../hooks/useSessionStorage';
import { Link } from 'react-router-dom';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { openSnackbar } from '../../../store/slices/snackbar';
import BarcodeScanner from '../tag/BarcodeScanner';
import DomainAddTwoToneIcon from '@mui/icons-material/DomainAddTwoTone';

const Transition = forwardRef((props: SlideProps, ref) => <Slide direction="left" ref={ref} {...props} />);

function a11yProps(index: number) {
    return {
        id: `handler-tab-${index}`,
        'aria-controls': `app-handler-tabpanel-${index}`
    };
}
// ==============================|| CALENDAR EVENT ADD / EDIT / DELETE ||============================== //

interface WebAppFormProps {
    onCancel: () => void;
}

const WebAppForm = ({ onCancel }: WebAppFormProps) => {
    const theme = useTheme();
    const intl = useIntl();
    const [storeMap, setStoreMap] = useState<any>({});
    const [site, setSite] = useState('');
    const [store, setStore] = useState('');
    const [tabIdx, setTabIdx] = useState(0);
    const [linkTag, setLinkTag] = useState('');
    const [linkItem, setLinkItem] = useState('');
    const [unlinkTag, setUnlinkTag] = useState('');
    const inputLinkTag = useRef(null);
    const inputLinkItem = useRef(null);
    const inputUnlinkTag = useRef(null);
    const [linkTagError, setLinkTagError] = useState(false);
    const [linkItemError, setLinkItemError] = useState(false);
    const [unlinkTagError, setUnlinkTagError] = useState(false);

    Howler.autoSuspend = false;
    const successBP = new Howl({
        src: 'wav/success.wav'
    });
    const errorBP = new Howl({
        src: 'wav/error.wav'
    });
    const failBP = new Howl({
        src: 'wav/fail.wav'
    });

    const [modal, setModal] = useState<{ open: boolean; mode: any; prev: string }>({ open: false, mode: '', prev: '' });

    const handleBarcodeRead = (mode: any, d: any) => {
        console.log(d);
        if (mode === 'tag') {
            if (tabIdx === 0) {
                const value = d.replace(/[^0-9]/g, '');
                setLinkTag(value);

                if (validTagID(value)) {
                    if (linkItem.length === 0) {
                        // @ts-ignore
                        inputLinkItem.current.focus();
                        setTimeout(() => {
                            setModal({ mode: 'item', open: true, prev: linkTag });
                        }, 500);
                    } else {
                        sendLink(value, linkItem, true);
                    }
                    setLinkTagError(false);
                } else {
                    setLinkTagError(true);
                }
            } else {
                const value = d.replace(/[^0-9]/g, '');
                setUnlinkTag(value);

                if (validTagID(value)) {
                    sendUnlink(value, true);
                    setUnlinkTagError(false);
                } else {
                    setUnlinkTagError(true);
                }
            }
        } else {
            setLinkItem(d);
            if (linkTag.length === 0) {
                // @ts-ignore
                inputLinkTag.current.focus();
            } else if (validTagID(linkTag)) {
                sendLink(linkTag, d, true);
            }
        }
    };

    const handleModalClose = () => {
        setModal({ open: false, mode: '', prev: '' });
    };

    const [routeState] = useSessionStorage('4seils-route', {
        site: '',
        name: '',
        picture: '',
        service: '',
        role: -1
    });
    const { data: siteList } = useQuery(
        ['get-account-site-list', routeState],
        async () => {
            let res = await axios.get<any>('/api/v1/account/site');

            let found = false;
            if (res.data.esl && res.data.esl.length > 0) {
                for (let i = 0; i < res.data.esl.length; i++) {
                    if (res.data.esl[i].id === routeState.site) {
                        setSite(routeState.site);
                        found = true;
                    }
                }
                if (!found) {
                    setSite(res.data.esl[0].id);
                }
            }
            return res.data.esl || [];
        },
        { refetchOnWindowFocus: false }
    );

    const { data: storeList } = useQuery(
        ['get-store-list', site],
        async () => {
            if (site.length === 0) {
                return [];
            }

            let res = await axios.post<any>('/api/v1/esl/site/' + site + '/store/search', {
                filter: {},
                sort: [],
                limit: 1000,
                offset: 0
            });

            let found = false;
            let storeDict: any = {};
            if (res.data.data && res.data.data.length > 0) {
                for (let i = 0; i < res.data.data.length; i++) {
                    let sc = res.data.data[i].storeCode;
                    storeDict[sc] = res.data.data[i];
                    if (sc === store) {
                        found = true;
                    }
                }
                if (!found) {
                    setStore(res.data.data[0].storeCode);
                }
            }
            setStoreMap(storeDict);
            return res.data.data || [];
        },
        { refetchOnWindowFocus: false }
    );

    useEffect(() => {
        if (tabIdx === 0) {
            // @ts-ignore
            inputLinkTag.current.focus();
        } else {
            // @ts-ignore
            inputUnlinkTag.current.focus();
        }
    }, [tabIdx]);

    const handleChangeTabIdx = (event: React.SyntheticEvent, newValue: number) => {
        setTabIdx(newValue);
    };

    const getLinkTagError = () => {
        if (linkTagError) {
            if (validTagID(linkTag)) {
                return '';
            } else if (linkTag.length === 0) {
                return intl.formatMessage({ id: 'this-field-is-required' });
            }
            return intl.formatMessage({ id: 'invalid-tag-id' });
        }
        return '';
    };

    const getUnlinkTagError = () => {
        if (unlinkTagError) {
            if (validTagID(unlinkTag)) {
                return '';
            } else if (unlinkTag.length === 0) {
                return intl.formatMessage({ id: 'this-field-is-required' });
            }
            return intl.formatMessage({ id: 'invalid-tag-id' });
        }
        return '';
    };
    const getLinkItemError = () => {
        if (linkItemError) {
            if (linkItem.length === 0) {
                return intl.formatMessage({ id: 'this-field-is-required' });
            }
        }
        return '';
    };

    const validTagID = (tagID: string) => {
        if (tagID.length === 16) {
            let c = 0;
            for (let i = 0; i < 7; i++) {
                c += Number(tagID[2 * i] + tagID[2 * i + 1]);
            }
            if (c % 100 === Number(tagID[14] + tagID[15])) {
                return true;
            }
            errorBP.play();
        }
        return false;
    };

    const handleLinkTagDown = (e: any) => {
        if (e.key === 'Enter') {
            handleLinkTagCheck();
        }
    };
    const handleLinkTagCheck = () => {
        const value = linkTag.replace(/[^0-9]/g, '');
        setLinkTag(value);

        if (validTagID(value)) {
            if (linkItem.length === 0) {
                // @ts-ignore
                inputLinkItem.current.focus();
            } else {
                sendLink(value, linkItem, false);
            }
            setLinkTagError(false);
        } else {
            setLinkTagError(true);
        }
    };

    const handleLinkItemDown = (e: any) => {
        if (e.key === 'Enter') {
            handleLinkItemCheck();
        }
    };
    const handleUnlinkTagDown = (e: any) => {
        if (e.key === 'Enter') {
            handleUnlinkTagCheck();
        }
    };

    const handleLinkItemCheck = () => {
        if (linkItem.length === 0) {
            setLinkItemError(true);
        } else if (linkTag.length === 0) {
            // @ts-ignore
            inputLinkTag.current.focus();
        } else if (validTagID(linkTag)) {
            sendLink(linkTag, linkItem, false);
        }
    };

    const handleUnlinkTagCheck = () => {
        const value = unlinkTag.replace(/[^0-9]/g, '');
        setUnlinkTag(value);

        if (validTagID(value)) {
            sendUnlink(value, false);
            setUnlinkTagError(false);
        } else {
            setUnlinkTagError(true);
        }
    };

    const sendLink = async (tag: any, item: any, runCamera: any) => {
        let linkData = {
            tag: tag,
            page: [
                {
                    no: 1,
                    item: [item]
                }
            ]
        };
        try {
            await axios.post('/api/v1/' + routeState.service + '/site/' + site + '/store/' + store + '/tag/link', linkData);
            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({ id: 'success' }),
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'primary'
                    },
                    close: true
                })
            );
            setLinkTag('');
            setLinkItem('');
            setLinkTagError(false);
            setLinkItemError(false);
            // @ts-ignore
            inputLinkTag.current.focus();
            successBP.play();

            if (runCamera) {
                if (tabIdx === 0) {
                    setModal({ mode: 'tag', open: true, prev: '' });
                } else if (tabIdx === 1) {
                    setModal({ mode: 'tag', open: true, prev: '' });
                }
            }
        } catch (err) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({ id: 'failed' }),
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
            failBP.play();
        }
    };

    const sendUnlink = async (tag: any, runCamera: any) => {
        try {
            await axios.post('/api/v1/' + routeState.service + '/site/' + site + '/store/' + store + '/tag/unlink', {
                tag: tag
            });
            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({ id: 'success' }),
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'primary'
                    },
                    close: true
                })
            );
            setUnlinkTag('');
            // @ts-ignore
            inputUnlinkTag.current.focus();
            successBP.play();
            setUnlinkTagError(false);

            if (runCamera) {
                if (tabIdx === 0) {
                    setModal({ mode: 'tag', open: true, prev: '' });
                } else if (tabIdx === 1) {
                    setModal({ mode: 'tag', open: true, prev: '' });
                }
            }
        } catch (err) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({ id: 'failed' }),
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
            failBP.play();
        }
    };

    // @ts-ignore
    return (
        <>
            <DialogTitle>
                <FormattedMessage id="web-app" />
            </DialogTitle>
            <DialogContent sx={{ p: 0 }}>
                <Grid item xs={12}>
                    <Tabs
                        value={tabIdx}
                        variant="scrollable"
                        indicatorColor="primary"
                        onChange={handleChangeTabIdx}
                        sx={{
                            pl: gridSpacing,
                            pr: gridSpacing,
                            minHeight: 0,
                            '& .MuiTabs-flexContainer': {
                                border: 'none'
                            },
                            '& a': {
                                minWidth: 10,
                                py: 1,
                                px: 1,
                                mr: 2.2,
                                color: theme.palette.grey[600],
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center'
                            },
                            '& a.Mui-selected': {
                                color: theme.palette.primary.dark
                            }
                        }}
                    >
                        <Tab component={Link} to="#" label={intl.formatMessage({ id: 'link' })} {...a11yProps(0)} />
                        <Tab component={Link} to="#" label={intl.formatMessage({ id: 'unlink' })} {...a11yProps(1)} />
                    </Tabs>
                </Grid>

                <Grid container spacing={gridSpacing} sx={{ mt: 0.25, pl: 3, pr: 3 }}>
                    {tabIdx === 0 && (
                        <>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label={intl.formatMessage({ id: 'tag-id' })}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        setLinkTag(e.target.value);
                                    }}
                                    value={linkTag}
                                    inputRef={inputLinkTag}
                                    error={linkTagError}
                                    helperText={getLinkTagError()}
                                    onInput={(e: any) => {
                                        const value = e.target.value.replace(/[^0-9]/g, '');
                                        setLinkTag(value);
                                    }}
                                    onKeyDown={handleLinkTagDown}
                                    onBlur={handleLinkTagCheck}
                                    InputProps={{
                                        inputMode: 'numeric',
                                        endAdornment:
                                            linkTag.length > 0 ? (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => {
                                                            setLinkTag('');
                                                            // @ts-ignore
                                                            inputLinkTag.current.focus();
                                                            setLinkTagError(false);
                                                        }}
                                                    >
                                                        <CloseTwoToneIcon fontSize="small" />
                                                    </IconButton>
                                                </InputAdornment>
                                            ) : (
                                                ''
                                            )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label={intl.formatMessage({ id: 'item' })}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        setLinkItem(e.target.value);
                                    }}
                                    value={linkItem}
                                    inputRef={inputLinkItem}
                                    error={linkItemError}
                                    helperText={getLinkItemError()}
                                    onKeyDown={handleLinkItemDown}
                                    onBlur={handleLinkItemCheck}
                                    InputProps={{
                                        endAdornment:
                                            linkTag.length > 0 ? (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => {
                                                            setLinkItem('');
                                                            // @ts-ignore
                                                            inputLinkItem.current.focus();
                                                            setLinkItemError(false);
                                                        }}
                                                    >
                                                        <CloseTwoToneIcon fontSize="small" />
                                                    </IconButton>
                                                </InputAdornment>
                                            ) : (
                                                ''
                                            )
                                    }}
                                />
                            </Grid>
                        </>
                    )}
                    {tabIdx === 1 && (
                        <>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label={intl.formatMessage({ id: 'tag-id' })}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        setUnlinkTag(e.target.value);
                                    }}
                                    value={unlinkTag}
                                    inputRef={inputUnlinkTag}
                                    error={unlinkTagError}
                                    helperText={getUnlinkTagError()}
                                    onKeyDown={handleUnlinkTagDown}
                                    InputProps={{
                                        inputMode: 'numeric',
                                        endAdornment:
                                            unlinkTag.length > 0 ? (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => {
                                                            setUnlinkTag('');
                                                            // @ts-ignore
                                                            inputUnlinkTag.current.focus();
                                                            setUnlinkTagError(false);
                                                        }}
                                                    >
                                                        <CloseTwoToneIcon fontSize="small" />
                                                    </IconButton>
                                                </InputAdornment>
                                            ) : (
                                                ''
                                            )
                                    }}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container alignItems="center" spacing={gridSpacing} justifyContent="space-between">
                    <Grid item>
                        <TextField
                            select
                            label={intl.formatMessage({ id: 'site' })}
                            value={site}
                            fullWidth
                            onChange={(e) => {
                                setSite(e.target.value);
                            }}
                            disabled={(siteList || []).length === 0}
                        >
                            {(siteList || []).map((item: any, index: any) => (
                                <MenuItem value={item.id} key={'site-' + index}>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        {!item.picture && (
                                            <Avatar
                                                sx={{
                                                    width: 32,
                                                    height: 32
                                                }}
                                            >
                                                {item.name[0]}
                                            </Avatar>
                                        )}
                                        {item.picture && (
                                            <Avatar
                                                src={item.picture}
                                                sx={{
                                                    width: 32,
                                                    height: 32
                                                }}
                                            ></Avatar>
                                        )}
                                        <Stack alignItems="left">
                                            <Typography variant="h3" color="primary">
                                                {item.name}
                                            </Typography>
                                            <Typography variant="caption" color="primary">
                                                ({item.id})
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item>
                        <TextField
                            select
                            label={intl.formatMessage({ id: 'store' })}
                            value={store}
                            fullWidth
                            onChange={(e) => {
                                setStore(e.target.value);
                            }}
                            disabled={(storeList || []).length === 0}
                            onClick={(event) => {
                                console.log('store list click');
                                event.stopPropagation();
                            }}
                        >
                            {(storeList || []).map((item: any, index: any) => (
                                <MenuItem value={item.storeCode} key={'store-' + index}>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Stack alignItems="left">
                                            <Typography variant="h3" color="primary">
                                                {item.name}
                                            </Typography>
                                            <Typography variant="caption" color="primary">
                                                ({item.storeCode})
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            </DialogActions>
            {/*<Fab*/}
            {/*    color="primary"*/}
            {/*    aria-label="scan"*/}
            {/*    sx={{ position: 'fixed', bottom: theme.spacing(16), right: theme.spacing(2) }}*/}
            {/*    onClick={() => {*/}
            {/*        if (tabIdx === 0) {*/}
            {/*            if (linkTag.length === 0) {*/}
            {/*                setModal({ mode: 'tag', open: true, prev: '' });*/}
            {/*            } else if (linkItem.length === 0) {*/}
            {/*                setModal({ mode: 'item', open: true, prev: linkTag });*/}
            {/*            }*/}
            {/*        } else if (tabIdx === 1) {*/}
            {/*            if (unlinkTag.length === 0) {*/}
            {/*                setModal({ mode: 'tag', open: true, prev: '' });*/}
            {/*            }*/}
            {/*        }*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <QrCodeScannerTwoToneIcon fontSize="medium" sx={{ color: 'white' }} />*/}
            {/*</Fab>*/}
            <Tooltip
                title={intl.formatMessage({ id: 'barcode-scanner' })}
                onClick={() => {
                    if (tabIdx === 0) {
                        if (linkTag.length === 0) {
                            setModal({ mode: 'tag', open: true, prev: '' });
                        } else if (linkItem.length === 0) {
                            setModal({ mode: 'item', open: true, prev: linkTag });
                        }
                    } else if (tabIdx === 1) {
                        if (unlinkTag.length === 0) {
                            setModal({ mode: 'tag', open: true, prev: '' });
                        }
                    }
                }}
            >
                <Fab
                    color="primary"
                    size="large"
                    sx={{ width: 56, height: 56, minHeight: 56, position: 'fixed', bottom: theme.spacing(16), right: theme.spacing(2) }}
                >
                    <QrCodeScannerTwoToneIcon fontSize="large" />
                </Fab>
            </Tooltip>
            <Dialog
                maxWidth="sm"
                fullWidth
                onClose={handleModalClose}
                open={modal.open}
                TransitionComponent={Transition}
                keepMounted
                sx={{
                    '&>div:nth-of-type(3)': {
                        justifyContent: 'flex-end',
                        '&>div': {
                            m: 0,
                            borderRadius: '0px',
                            maxWidth: 450,
                            maxHeight: '100%',
                            minHeight: '100%',
                            minWidth: 450,
                            [theme.breakpoints.down('md')]: {
                                maxWidth: 'auto',
                                minWidth: 'auto'
                            }
                        }
                    }
                }}
            >
                {modal.open && (
                    <BarcodeScanner mode={modal.mode} prev={modal.prev} onRead={handleBarcodeRead} onCancel={handleModalClose} />
                )}
            </Dialog>
        </>
    );
};

export default WebAppForm;
