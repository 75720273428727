// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Autocomplete,
    Box,
    Button,
    CardContent,
    Checkbox,
    Chip,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Select,
    TableCell,
    TextField,
    Typography
} from '@mui/material';

import { gridSpacing } from 'store/constant';

// assets
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider, FormikValues } from 'formik';
import { MobileDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import SubCard from '../../../ui-component/cards/SubCard';
import AddIcon from '@mui/icons-material/Add';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { FormattedMessage, useIntl } from 'react-intl';

// ==============================|| CALENDAR EVENT ADD / EDIT / DELETE ||============================== //

interface CreateLicenseFormProps {
    handleCreate: (d: FormikValues) => void;
    onCancel: () => void;
}

const CreateESLLicenseForm = ({ handleCreate, onCancel }: CreateLicenseFormProps) => {
    const intl = useIntl();

    const PATSchema = Yup.object().shape({
        name: Yup.string().required(intl.formatMessage({ id: 'this-field-is-required' })),
        maxApCount: Yup.number().min(0),
        maxTagCount: Yup.number().min(0),
        maxStoreCount: Yup.number().min(0),
        maxItemDbSize: Yup.number().min(0),
        expiryDate: Yup.number(),
        setExpiryDate: Yup.bool()
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            maxApCount: 0,
            maxTagCount: 0,
            maxStoreCount: 0,
            maxItemDbSize: 0,
            expiryDate: 0,
            setExpiryDate: false
        },
        validationSchema: PATSchema,
        onSubmit: async (values, { resetForm, setSubmitting }) => {
            try {
                values.expiryDate = Math.round(expiryDate.getTime() / 1000);
                await handleCreate(values);
                resetForm();
                onCancel();
                setSubmitting(false);
            } catch (err) {
                console.error(err);
            }
        }
    });

    const { values, errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;
    const [expiryDate, setExpiryDate] = useState(values.expiryDate > 0 ? new Date(values.expiryDate * 1000) : new Date());

    return (
        <FormikProvider value={formik}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DialogTitle>{intl.formatMessage({ id: 'create-license' })}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={gridSpacing} sx={{ mt: 0.25 }}>
                        <Grid item xs={12}>
                            <TextField
                                label={intl.formatMessage({ id: 'name' })}
                                fullWidth
                                {...getFieldProps('name')}
                                error={Boolean(touched.name && errors.name)}
                                helperText={touched.name && errors.name}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label={intl.formatMessage({ id: 'max-ap-count' })}
                                fullWidth
                                type="number"
                                {...getFieldProps('maxApCount')}
                                error={Boolean(touched.maxApCount && errors.maxApCount)}
                                helperText={touched.maxApCount && errors.maxApCount}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label={intl.formatMessage({ id: 'max-tag-count' })}
                                fullWidth
                                type="number"
                                {...getFieldProps('maxTagCount')}
                                error={Boolean(touched.maxTagCount && errors.maxTagCount)}
                                helperText={touched.maxTagCount && errors.maxTagCount}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label={intl.formatMessage({ id: 'max-item-db-size' })}
                                fullWidth
                                type="number"
                                {...getFieldProps('maxItemDbSize')}
                                error={Boolean(touched.maxItemDbSize && errors.maxItemDbSize)}
                                helperText={touched.maxItemDbSize && errors.maxItemDbSize}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label={intl.formatMessage({ id: 'max-store-count' })}
                                fullWidth
                                type="number"
                                {...getFieldProps('maxStoreCount')}
                                error={Boolean(touched.maxStoreCount && errors.maxStoreCount)}
                                helperText={touched.maxStoreCount && errors.maxStoreCount}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox checked={values.setExpiryDate} />}
                                onChange={(event, checked) => {
                                    formik.setFieldValue('setExpiryDate', checked);
                                }}
                                label={intl.formatMessage({ id: 'set-expiry-date' })}
                            />
                        </Grid>

                        {formik.values.setExpiryDate && (
                            <Grid item xs={12}>
                                <MobileDateTimePicker
                                    value={expiryDate}
                                    onChange={(newValue: any) => {
                                        setExpiryDate(newValue);
                                    }}
                                    label={intl.formatMessage({ id: 'expiry-date' })}
                                    onError={console.log}
                                    minDate={new Date()}
                                    inputFormat="yyyy/MM/dd hh:mm a"
                                    mask="___/__/__ __:__ _M"
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            margin="normal"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <DateRangeIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>

                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <DialogActions>
                        <Button type="submit" variant="contained" disabled={isSubmitting}>
                            <FormattedMessage id="create" />
                        </Button>
                        <Button variant="text" color="error" onClick={onCancel}>
                            <FormattedMessage id="cancel" />
                        </Button>
                    </DialogActions>
                </Form>
            </LocalizationProvider>
        </FormikProvider>
    );
};

export default CreateESLLicenseForm;
