// material-ui
import { useTheme, Theme } from '@mui/material/styles';
import {
    Box,
    Button,
    Typography,
    Paper,
    TableHead,
    TableRow,
    TableCell,
    Checkbox,
    TableSortLabel,
    TableContainer,
    Table,
    IconButton,
    TablePagination,
    TableBody,
    Tooltip,
    Toolbar,
    CardContent,
    Grid,
    Dialog,
    SlideProps,
    Slide,
    Alert,
    TextField,
    InputAdornment,
    Stack,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import useSessionStorage from '../../../hooks/useSessionStorage';
import React, { forwardRef, useEffect, useState } from 'react';
import axios from '../../../utils/axios';
import { KeyedObject, EnhancedTableHeadProps, HeadCell } from 'types';
import { visuallyHidden } from '@mui/utils';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import { useQuery } from 'react-query';
import Loader from '../../../ui-component/Loader';
import RefreshTwoToneIcon from '@mui/icons-material/RefreshTwoTone';
import Chip from 'ui-component/extended/Chip';
import { useDispatch } from '../../../store';
import FileDownloadTwoToneIcon from '@mui/icons-material/FileDownloadTwoTone';
import KeyTwoToneIcon from '@mui/icons-material/KeyTwoTone';
import { format } from 'date-fns';
import useAuth from '../../../hooks/useAuth';
import { openSnackbar } from '../../../store/slices/snackbar';
import { exportToCsv } from '../../../utils/exportToCsv';
import { gridSpacing } from '../../../store/constant';
import SubCard from '../../../ui-component/cards/SubCard';
import CloseIcon from '@mui/icons-material/Close';
import { FormattedMessage, useIntl } from 'react-intl';
import CreateESLLicenseForm from './CreateESLLicenseForm';
import { FormikValues } from 'formik';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import CachedTwoToneIcon from '@mui/icons-material/CachedTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';

const columnList = [
    'name',
    'key',
    'createdBy',
    'maxApCount',
    'maxTagCount',
    'maxStoreCount',
    'maxItemDbSize',
    'expiryDate',
    'usedBy',
    'usedFor',
    'usedAt'
];

const viewItemList: any = [
    { key: 'name', text: 'name' },
    { key: 'key', text: 'key' },
    { key: 'createdBy', text: 'created-by' },
    { key: 'maxApCount', text: 'max-ap-count', mode: 'esl' },
    { key: 'maxTagCount', text: 'max-tag-count', mode: 'esl' },
    { key: 'maxStoreCount', text: 'max-store-count', mode: 'esl' },
    { key: 'maxItemDbSize', text: 'max-item-db-size', mode: 'esl' },
    { key: 'expiryDate', text: 'expiry-date' },
    { key: 'usedBy', text: 'used-by' },
    { key: 'usedFor', text: 'used-for' },
    { key: 'usedAt', text: 'used-at' }
];

let headCellList: HeadCell[] = [];

// ==============================|| TABLE - HEADER ||============================== //

interface LicenseTableHeadProps extends EnhancedTableHeadProps {
    theme: Theme;
    selected: string[];
    setSelected: any;
    headCells: HeadCell[];
}

function LicenseTableHead({ sort, theme, onRequestSort }: LicenseTableHeadProps) {
    const createSortHandler = (property: HeadCell) => (event: React.SyntheticEvent) => {
        if (property.sortable) onRequestSort(event, property.id);
    };

    return (
        <TableHead>
            <TableRow>
                {/*<TableCell key="id" align="center" padding="normal" sortDirection={sort.id !== undefined ? sort.id : false}>*/}
                {/*    <TableSortLabel*/}
                {/*        active={sort.id !== undefined}*/}
                {/*        direction={sort.id}*/}
                {/*        onClick={createSortHandler({ id: 'tagMac', sortable: true, label: 'Tag' })}*/}
                {/*    >*/}
                {/*        Tag*/}
                {/*        {sort.id !== undefined ? (*/}
                {/*            <Box component="span" sx={visuallyHidden}>*/}
                {/*                {sort.id === 'desc' ? 'sorted descending' : 'sorted ascending'}*/}
                {/*            </Box>*/}
                {/*        ) : null}*/}
                {/*    </TableSortLabel>*/}
                {/*</TableCell>*/}
                {headCellList.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="center"
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={sort[headCell.id] !== undefined ? sort[headCell.id] : false}
                        sx={headCell.style}
                    >
                        <TableSortLabel
                            active={sort[headCell.id] !== undefined}
                            direction={sort[headCell.id]}
                            onClick={createSortHandler(headCell)}
                        >
                            {headCell.label}
                            {sort[headCell.id] !== undefined ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {sort[headCell.id] === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell sortDirection={false} align="center" sx={{ pr: 3 }}>
                    <Typography variant="subtitle1" sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}>
                        <FormattedMessage id="action" />
                    </Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

// ==============================|| TABLE - HEADER TOOLBAR ||============================== //
const Transition = forwardRef((props: SlideProps, ref) => <Slide direction="left" ref={ref} {...props} />);

interface LicenseTableProps {}

const LicenseTable = ({}: LicenseTableProps) => {
    const theme = useTheme();
    const intl = useIntl();

    const [page, setPage] = React.useState(0);
    // const [keyword, setKeyword] = React.useState('');
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [selected, setSelected] = React.useState<string[]>([]);
    const [sort, setSort] = useState<KeyedObject>({});
    const [sortOrder, setSortOrder] = useState<string[]>([]);
    // const [columnList, setColumnList] = useState<string[]>(defaultList);
    const [modal, setModal] = useState<{ open: boolean; mode: string; status?: number }>({ open: false, mode: '' });
    const [confirm, setConfirm] = useState<{ data: any; open: boolean; mode: string; text: string }>({
        data: null,
        open: false,
        mode: '',
        text: ''
    });

    const dispatch = useDispatch();
    const { account } = useAuth();
    const [filter, setFilter] = useState<{ action: number[] }>({
        action: []
    });

    const [routeState] = useSessionStorage('4seils-route', {
        site: '',
        name: '',
        picture: '',
        service: '',
        role: -1
    });

    const {
        data: response,
        isLoading,
        refetch,
        error
    } = useQuery(['get-license-list', routeState.service, page, rowsPerPage, sort, filter], async () => {
        let sortList = [];
        for (let i = 0; i < sortOrder.length; i++) {
            sortList.push({ key: sortOrder[i], direction: sort[sortOrder[i]] });
        }

        let conditions: any = [];
        let filterVal: { and?: any; or?: any } = {};

        // if (filter.action.length > 0) {
        //     conditions.push({ key: 'action', operator: 'in', value: filter.action });
        // }

        if (conditions.length > 0) {
            filterVal.and = conditions;
        }

        let res = await axios.post<{ data: any[]; total: number; filtered: number }>('/api/v1/' + routeState.service + '/license/search', {
            filter: filterVal,
            offset: page * rowsPerPage,
            limit: rowsPerPage,
            sort: sortList
        });

        if (page * rowsPerPage > res.data.filtered) {
            setPage(0);
        }

        return res.data;
    });

    const handleExportClick = async () => {
        let offset = 0;
        let finished = false;
        let rows: any[] = [];
        try {
            let sortList = [];
            for (let i = 0; i < sortOrder.length; i++) {
                sortList.push({ key: sortOrder[i], direction: sort[sortOrder[i]] });
            }

            let conditions: any = [];
            let filterVal: { and?: any; or?: any } = {};
            //
            // if (filter.action.length > 0) {
            //     conditions.push({ key: 'action', operator: 'in', value: filter.action });
            // }

            if (conditions.length > 0) {
                filterVal.and = conditions;
            }

            while (!finished) {
                let res = await axios.post<{ data: any[]; total: number; filtered: number }>(
                    '/api/v1/' + routeState.service + '/license/search',
                    {
                        filter: filterVal,
                        offset: offset,
                        limit: 100,
                        sort: sortList
                    }
                );

                // @ts-ignore
                rows = [...rows, ...res.data.data];
                if (res.data.filtered === rows.length || (res.data.data as any).length === 0) {
                    finished = true;
                }
                offset += 100;
            }

            let csvData = [];
            let column = [...viewItemList];
            for (let i = 0; i < rows.length; i++) {
                let d = {};
                for (let j = 0; j < column.length; j++) {
                    let row = rows[i];
                    // @ts-ignore
                    // d[column[j].text] = rows[i][column[j].key];
                    // d.ID = rows[i].id;

                    let v = rows[i][column[j].key];
                    if (column[j].key === 'expiryDate') {
                        if (row.expiryDate != null) {
                            v = format(new Date(1000 * row.expiryDate), 'yyyy-MM-dd HH:mm:ss');
                        } else {
                            v = '';
                        }
                    } else if (column[j].key === 'status') {
                        v = getStatusText(row.status);
                    }

                    // @ts-ignore
                    d[column[j].text] = v;
                }
                csvData.push(d);
            }

            if (!csvData.length) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: intl.formatMessage({ id: 'no-data-to-download' }),
                        anchorOrigin: { vertical: 'top', horizontal: 'right' },
                        variant: 'alert',
                        alert: {
                            color: 'warning'
                        },
                        close: true
                    })
                );
            }
            exportToCsv('license.csv', csvData);
        } catch (err) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({ id: 'failed' }),
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };

    useEffect(() => {
        if (error) {
            console.log(error);
        }
    }, [error]);

    useEffect(() => {
        headCellList = [];
        for (let i = 0; i < viewItemList.length; i++) {
            if (viewItemList[i].mode === undefined || viewItemList[i].mode === routeState.service) {
                if (columnList.indexOf(viewItemList[i].key) > -1) {
                    headCellList.push({
                        id: viewItemList[i].key,
                        disablePadding: false,
                        label: intl.formatMessage({ id: viewItemList[i].text }),
                        sortable: viewItemList[i].sortable != false,
                        style: { minWidth: 120, p: 0 }
                    });
                }
            }
        }
    }, [columnList, routeState.service]);

    const getStatusText = (status: number) => {
        if (status === 1) return intl.formatMessage({ id: 'in-process' });
        else if (status === 2) return intl.formatMessage({ id: 'complete' });
        else if (status === 3) return intl.formatMessage({ id: 'skipped' });
        else if (status === 4) return intl.formatMessage({ id: 'expired' });
        return 'UNKNOWN';
    };

    const handleSearchFilterClick = () => {
        setModal({ open: true, mode: 'search' });
    };

    const handleColumnFilterClick = () => {
        setModal({ open: true, mode: 'column' });
    };

    const handleModalClose = () => {
        setModal({ open: false, mode: '' });
    };

    const handleNewLicenseClick = () => {
        setModal({ open: true, mode: 'new' });
    };

    const handleConfirmClose = () => {
        setConfirm({ data: null, open: false, mode: '', text: '' });
    };

    const handleRequestSort = (event: React.SyntheticEvent, property: string) => {
        if ((sort as any)[property] === undefined) {
            setSort((current) => {
                const copy = { ...current };
                copy[property] = 'asc';
                return copy;
            });
            setSortOrder((current) => {
                const copy = [...current];
                let index = copy.indexOf(property);
                if (index !== -1) {
                    copy.splice(index, 1);
                }
                copy.push(property);
                return copy;
            });
        } else if (sort[property] === 'asc') {
            setSort((current) => {
                const copy = { ...current };
                copy[property] = 'desc';
                return copy;
            });
            setSortOrder((current) => {
                const copy = [...current];
                let index = copy.indexOf(property);
                if (index !== -1) {
                    copy.splice(index, 1);
                }
                copy.push(property);
                return copy;
            });
        } else {
            setSort((current) => {
                const copy = { ...current };
                delete copy[property];
                return copy;
            });
            setSortOrder((current) => {
                const copy = [...current];
                let index = copy.indexOf(property);
                if (index !== -1) {
                    copy.splice(index, 1);
                }
                return copy;
            });
        }
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setSelected([]);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined) => {
        setRowsPerPage(parseInt(event?.target.value!, 10));
        setPage(0);
    };

    const handleFilter = (type: string, params: number) => {
        if (type === 'action') {
            if (params === 0) {
                setFilter({
                    ...filter,
                    action: []
                });
            } else {
                if (filter.action.some((item) => item === params)) {
                    setFilter({ ...filter, action: filter.action.filter((item) => item !== params) });
                } else {
                    if ([...filter.action, params].length === 4) {
                        setFilter({
                            ...filter,
                            action: []
                        });
                    } else {
                        setFilter({ ...filter, action: [...filter.action, params] });
                    }
                }
            }
        } else if (type === 'reset') {
            setFilter({
                action: []
            });
        }
    };

    const handleDeleteClick = (data: any) => {
        setConfirm({ data: data, open: true, mode: 'delete', text: intl.formatMessage({ id: 'license-delete-warning' }) });
    };

    const handleConfirmOk = async () => {
        if (confirm.mode === 'delete') {
            try {
                await axios.delete('/api/v1/' + routeState.service + '/license/' + confirm.data.id);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: intl.formatMessage({ id: 'success' }),
                        anchorOrigin: { vertical: 'top', horizontal: 'right' },
                        variant: 'alert',
                        alert: {
                            color: 'primary'
                        },
                        close: true
                    })
                );
            } catch (err) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: intl.formatMessage({ id: 'failed' }),
                        anchorOrigin: { vertical: 'top', horizontal: 'right' },
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
            }
        }
        setConfirm({ data: null, open: false, mode: '', text: '' });
        await refetch();
    };

    const handleCreate = async (update: FormikValues) => {
        console.log(update);
        // dispatch(updateEvent({ eventId, update }));
        let data: any = { name: update.name };
        let url = '/api/v1/' + routeState.service + '/license';
        // if (update.id !== 0) {
        //     url = '/api/v1/account/pat/' + update.id;
        // }
        //
        if (update.setExpiryDate) {
            data.expiryDate = update.expiryDate;
        } else {
            data.expiryDate = 0;
        }

        if (update.maxStoreCount > 0) {
            data.maxStoreCount = update.maxStoreCount;
        }
        if (update.maxApCount > 0) {
            data.maxApCount = update.maxApCount;
        }
        if (update.maxTagCount > 0) {
            data.maxTagCount = update.maxTagCount;
        }
        if (update.maxItemDbSize > 0) {
            data.maxItemDbSize = update.maxItemDbSize;
        }

        try {
            let res = await axios.post(url, data);
            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({ id: 'success' }),
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'primary'
                    },
                    close: true
                })
            );
            await refetch();
        } catch (err) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({ id: 'failed' }),
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };

    // Avoid a layout jump when reaching the last page with empty rows.

    // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - (response?.filtered || 0)) : 0;

    const getCell = (headCell: any, row: any) => {
        if (headCell.id === 'expiryDate') {
            if (row.expiryDate != null) return format(new Date(1000 * row.expiryDate), 'yyyy-MM-dd HH:mm:ss');
            return '';
        } else if (headCell.id === 'usedAt') {
            if (row.usedAt != null) return format(new Date(1000 * row.usedAt), 'yyyy-MM-dd HH:mm:ss');
            return '';
        }
        return row[headCell.id];
    };

    return (
        <>
            {isLoading && <Loader />}
            <CardContent sx={{ width: '100%', pt: 0 }}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12} sx={{ textAlign: 'right' }}>
                        {/*<Tooltip title={intl.formatMessage({ id: 'filter' })} onClick={handleSearchFilterClick}>*/}
                        {/*    <IconButton size="large">*/}
                        {/*        <FilterAltTwoToneIcon color="secondary" />*/}
                        {/*    </IconButton>*/}
                        {/*</Tooltip>*/}

                        <Tooltip title={intl.formatMessage({ id: 'create-license' })} onClick={handleNewLicenseClick}>
                            <IconButton size="large">
                                <KeyTwoToneIcon color="primary" />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={intl.formatMessage({ id: 'export-csv' })} onClick={handleExportClick}>
                            <IconButton size="large">
                                <FileDownloadTwoToneIcon color="primary" />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={intl.formatMessage({ id: 'refresh' })} onClick={() => refetch()}>
                            <IconButton size="large">
                                <RefreshTwoToneIcon color="info" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </CardContent>

            {filter.action.length > 0 && (
                <>
                    <Grid container spacing={gridSpacing} sx={{ p: gridSpacing, pt: 0, pb: 0 }} alignItems="center">
                        {filter.action.length > 0 && (
                            <Grid item>
                                <SubCard content={false}>
                                    <CardContent sx={{ pb: '12px !important', p: 1.5 }}>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item>
                                                <Typography variant="subtitle1">
                                                    <FormattedMessage id="action" />
                                                </Typography>
                                            </Grid>
                                            {filter.action.map((item: number, index: number) => (
                                                <Grid item key={index}>
                                                    <Chip
                                                        size="small"
                                                        label={getStatusText(item)}
                                                        onDelete={() => handleFilter('action', item)}
                                                        chipcolor="secondary"
                                                        sx={{ borderRadius: '4px' }}
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </CardContent>
                                </SubCard>
                            </Grid>
                        )}
                        <Grid item>
                            <Button variant="outlined" startIcon={<CloseIcon />} color="error" onClick={() => handleFilter('reset', 0)}>
                                <FormattedMessage id="clear-all" />
                            </Button>
                        </Grid>

                        <Grid item>
                            <Typography variant="body2" color={theme.palette.grey[500]}>
                                {intl.formatMessage({ id: 'filtered' })}: {response?.filtered} / {intl.formatMessage({ id: 'total' })}:{' '}
                                {response?.total}
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            )}

            <TableContainer>
                <Table aria-labelledby="tableTitle" size="medium">
                    <LicenseTableHead
                        numSelected={selected.length}
                        sort={sort}
                        onRequestSort={handleRequestSort}
                        rowCount={response?.data.length || 0}
                        selected={selected}
                        setSelected={setSelected}
                        theme={theme}
                        headCells={headCellList}
                    />
                    <TableBody>
                        {/*{stableSort(rows, getComparator(order, orderBy))*/}
                        {/*    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)*/}

                        {!isLoading &&
                            (response?.data || []).map(function (row: any, index: any) {
                                /** Make sure no display bugs if row isn't an OrderData object */
                                if (typeof row === 'number') return null;
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id + index}>
                                        {/*<TableCell align="center" component="th" id={labelId} scope="row">*/}
                                        {/*    <Typography*/}
                                        {/*        variant="subtitle1"*/}
                                        {/*        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}*/}
                                        {/*    >*/}
                                        {/*        {row.tagMac}*/}
                                        {/*    </Typography>*/}
                                        {/*</TableCell>*/}

                                        {headCellList.map((headCell) => (
                                            <TableCell
                                                key={headCell.id}
                                                align="center"
                                                padding="normal"
                                                sx={{ pt: 0, pb: 0, pl: headCell.id === 'name' ? 3 : 2 }}
                                            >
                                                {getCell(headCell, row)}
                                            </TableCell>
                                        ))}

                                        <TableCell align="center" sx={{ pr: 3, pt: 0, pb: 0 }}>
                                            <Stack direction="row" justifyContent="center" alignItems="center">
                                                <Tooltip
                                                    title={intl.formatMessage({ id: 'delete' })}
                                                    onClick={() => handleDeleteClick(row)}
                                                >
                                                    <IconButton size="large">
                                                        <DeleteTwoToneIcon fontSize="small" color="error" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        {/*{emptyRows > 0 && (*/}
                        {/*    <TableRow*/}
                        {/*        style={{*/}
                        {/*            height: 53 * emptyRows*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <TableCell colSpan={6} />*/}
                        {/*    </TableRow>*/}
                        {/*)}*/}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* table data */}
            {!isLoading && (
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={(response as any)?.filtered || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{ width: '100%' }}
                    labelRowsPerPage={intl.formatMessage({ id: 'rows-per-page' })}
                    labelDisplayedRows={({ from, to, count }) => {
                        return `${from}–${to} ${intl.formatMessage({ id: 'of' })} ${
                            count !== -1 ? count : `${intl.formatMessage({ id: 'more-than' }, { to: to })}`
                        }`;
                    }}
                />
            )}

            <Dialog
                maxWidth="sm"
                fullWidth
                onClose={handleModalClose}
                open={modal.open}
                TransitionComponent={Transition}
                keepMounted
                sx={{
                    '&>div:nth-of-type(3)': {
                        justifyContent: 'flex-end',
                        '&>div': {
                            m: 0,
                            borderRadius: '0px',
                            maxWidth: 450,
                            maxHeight: '100%',
                            minHeight: '100%',
                            [theme.breakpoints.down('md')]: {
                                maxWidth: 'auto',
                                minWidth: 'auto'
                            }
                        }
                    }
                }}
            >
                {modal.open && modal.mode === 'new' && <CreateESLLicenseForm handleCreate={handleCreate} onCancel={handleModalClose} />}
                {/*{modal.open && modal.mode === 'column' && (*/}
                {/*    <ColumnFilterForm*/}
                {/*        onCancel={handleModalClose}*/}
                {/*        viewItemList={viewItemList}*/}
                {/*        setColumnList={setColumnList}*/}
                {/*        columnList={columnList}*/}
                {/*        defaultList={defaultList}*/}
                {/*    />*/}
                {/*)}*/}
                {/*{modal.open && modal.mode === 'search' && (*/}
                {/*    // <TaskDetailSearchFilterForm*/}
                {/*    //     filter={filter}*/}
                {/*    //     handleFilter={handleFilter}*/}
                {/*    //     isExpired={isExpired}*/}
                {/*    //     onCancel={handleModalClose}*/}
                {/*    // />*/}
                {/*)}*/}
            </Dialog>
            <Dialog
                open={confirm.open}
                onClose={handleConfirmClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ p: 3 }}
            >
                {confirm.open && (
                    <>
                        <DialogTitle id="alert-dialog-title">
                            <FormattedMessage id="are-you-sure" />
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Typography variant="body2" component="span">
                                    {confirm.text}
                                </Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ pr: 2.5 }}>
                            <Button
                                sx={{ color: theme.palette.error.dark, borderColor: theme.palette.error.dark }}
                                onClick={handleConfirmClose}
                                color="secondary"
                            >
                                <FormattedMessage id="close" />
                            </Button>
                            {confirm.mode !== 'token' && (
                                <Button variant="contained" size="small" onClick={handleConfirmOk} autoFocus>
                                    <FormattedMessage id="ok" />
                                </Button>
                            )}
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    );
};

export default LicenseTable;
