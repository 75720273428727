// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Button,
    CardContent,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Tab,
    Tabs,
    TextField
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// third-party
import _ from 'lodash';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider, FormikValues } from 'formik';

import { gridSpacing } from 'store/constant';

// assets
import React, { useEffect, useRef, useState } from 'react';
import axios from '../../../utils/axios';
import SubCard from '../../../ui-component/cards/SubCard';
import { useQuery } from 'react-query';
import { REGISTER } from '../../../store/actions';
import { useDispatch } from '../../../store';
import useLocalStorage from '../../../hooks/useLocalStorage';
import Loader from '../../../ui-component/Loader';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
// constant
const getInitialValues = (config: FormikValues | null) => {
    const newConfig = {
        enableSMTP: false,
        enableTLS: true,
        username: '',
        email: '',
        password: '',
        host: '',
        port: 0
    };

    if (newConfig) {
        return _.merge({}, newConfig, config);
    }

    return newConfig;
};

// ==============================|| CALENDAR EVENT ADD / EDIT / DELETE ||============================== //

interface SystemConfigFormProps {
    onCancel: () => void;
}

function a11yProps(index: number) {
    return {
        id: `system-config-tab-${index}`,
        'aria-controls': `system-config-tabpanel-${index}`
    };
}

const SystemConfigForm = ({ onCancel }: SystemConfigFormProps) => {
    const theme = useTheme();
    const intl = useIntl();
    const dispatch = useDispatch();

    let [tabIdx, setTabIdx] = useState(0);

    const {
        data: smList,
        isLoading,
        refetch,
        error
    } = useQuery(
        ['get-system-manager-list'],
        async () => {
            let res = await axios.get('/api/v1/account/system');
            return res.data || [];
        },
        { refetchOnWindowFocus: false }
    );

    const SystemConfigSchema = Yup.object().shape({
        enableSMTP: Yup.bool(),
        enableTLS: Yup.bool(),
        username: Yup.string(),
        email: Yup.string(),
        password: Yup.string(),
        host: Yup.string(),
        port: Yup.number()
        // id: Yup.string()
        //     .strict()
        //     .min(4)
        //     .max(20)
        //     .lowercase('ID must be a lowercase string')
        //     .required('ID is required')
        //     .matches(/^[a-z0-9\s]+$/, 'Only alphanumeric characters are allowed for this field '),
        // name: Yup.string().min(1).max(64).required('Name is required'),
        // email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        // password: Yup.string()
        //     .min(8)
        //     .max(100)
        //     .oneOf([Yup.ref('confirmPassword'), null], 'Passwords must match'),
        // confirmPassword: Yup.string()
        //     .min(8)
        //     .max(100)
        //     .oneOf([Yup.ref('password'), null], 'Passwords must match')
    });

    const formik = useFormik({
        initialValues: getInitialValues({}),
        validationSchema: SystemConfigSchema,
        onSubmit: async (values, { resetForm, setSubmitting }) => {}
    });

    const handleChangeTabIdx = (event: React.SyntheticEvent, newValue: number) => {
        setTabIdx(newValue);
    };

    const { values, errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

    if (isLoading) return <Loader />;

    // @ts-ignore
    return (
        <FormikProvider value={formik}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DialogTitle>
                    <FormattedMessage id="system-configuration" />
                </DialogTitle>
                {/*<Divider />*/}
                <DialogContent>
                    <Grid container spacing={gridSpacing} sx={{ mt: 0.25 }}>
                        <Grid item xs={12}>
                            <Tabs
                                value={tabIdx}
                                variant="scrollable"
                                indicatorColor="primary"
                                onChange={handleChangeTabIdx}
                                sx={{
                                    minHeight: 0,
                                    '& .MuiTabs-flexContainer': {
                                        border: 'none'
                                    },
                                    '& a': {
                                        minWidth: 10,
                                        py: 1,
                                        px: 1,
                                        mr: 2.2,
                                        color: theme.palette.grey[600],
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    },
                                    '& a.Mui-selected': {
                                        color: theme.palette.primary.dark
                                    }
                                }}
                            >
                                <Tab component={Link} to="#" label={intl.formatMessage({ id: 'smtp' })} {...a11yProps(0)} />
                                <Tab
                                    component={Link}
                                    to="#"
                                    label={intl.formatMessage({ id: 'administrator' })}
                                    {...a11yProps(1)}
                                    disabled={true}
                                />
                            </Tabs>
                        </Grid>
                        {tabIdx === 0 && (
                            <>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox checked={values.enableSMTP} />}
                                        onChange={(event, checked) => {
                                            formik.setFieldValue('enableSMTP', checked);
                                        }}
                                        label={intl.formatMessage({ id: 'enable-smtp' })}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox checked={values.enableTLS} />}
                                        onChange={(event, checked) => {
                                            formik.setFieldValue('enableTLS', checked);
                                        }}
                                        label={intl.formatMessage({ id: 'enable-tls' })}
                                        disabled={!values.enableSMTP}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={intl.formatMessage({ id: 'username' })}
                                        {...getFieldProps('username')}
                                        error={Boolean(touched.username && errors.username)}
                                        helperText={touched.username && errors.username}
                                        disabled={!values.enableSMTP}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={intl.formatMessage({ id: 'email' })}
                                        {...getFieldProps('email')}
                                        error={Boolean(touched.email && errors.email)}
                                        helperText={touched.email && errors.email}
                                        disabled={!values.enableSMTP}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={intl.formatMessage({ id: 'password' })}
                                        {...getFieldProps('password')}
                                        error={Boolean(touched.password && errors.password)}
                                        helperText={touched.password && errors.password}
                                        disabled={!values.enableSMTP}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={intl.formatMessage({ id: 'host' })}
                                        {...getFieldProps('host')}
                                        error={Boolean(touched.host && errors.host)}
                                        helperText={touched.host && errors.host}
                                        disabled={!values.enableSMTP}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={intl.formatMessage({ id: 'port' })}
                                        {...getFieldProps('port')}
                                        error={Boolean(touched.port && errors.port)}
                                        helperText={touched.port && errors.port}
                                        disabled={!values.enableSMTP}
                                    />
                                </Grid>
                            </>
                        )}
                        {tabIdx === 1 && (
                            <>
                                <Grid item xs={12}></Grid>
                            </>
                        )}
                    </Grid>
                </DialogContent>

                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <DialogActions>
                        <Box sx={{ flexGrow: 1 }} />
                        <Button type="submit" variant="contained" disabled={isSubmitting}>
                            <FormattedMessage id="save" />
                        </Button>
                        <Button variant="text" color="error" onClick={onCancel}>
                            <FormattedMessage id="cancel" />
                        </Button>
                    </DialogActions>
                </Form>
            </LocalizationProvider>
        </FormikProvider>
    );
};

export default SystemConfigForm;
