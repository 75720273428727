import React, { createContext, useEffect, useReducer } from 'react';

// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';

// types
import { KeyedObject } from 'types';
import { InitialLoginContextProps, JWTContextType } from 'types/auth';
import useLocalStorage from '../hooks/useLocalStorage';

const chance = new Chance();

// constant
const initialState: InitialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    account: null
};

const verifyToken: (st: string) => boolean = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded: KeyedObject = jwtDecode(serviceToken);
    // console.log(decoded);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
     */
    // console.log(decoded.exp > Date.now() / 1000);
    return decoded.exp > Date.now() / 1000;
};

const setToken = (accessToken?: string | null, refreshToken?: string | null) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
        localStorage.removeItem('accessToken');
        delete axios.defaults.headers.common.Authorization;
    }

    if (refreshToken) {
        localStorage.setItem('refreshToken', refreshToken);
    } else {
        localStorage.removeItem('refreshToken');
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const [, setAccountInfo] = useLocalStorage('account', {});

    useEffect(() => {
        const init = async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken');
                if (accessToken && verifyToken(accessToken)) {
                    // setToken(accessToken);
                    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
                    const response = await axios.get('/api/v1/account/me');
                    const account = response.data;
                    setAccountInfo(account);
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            account
                        }
                    });
                } else {
                    const refreshToken = window.localStorage.getItem('refreshToken');
                    if (refreshToken && verifyToken(refreshToken)) {
                        try {
                            const resp = await axios.post('/api/v1/account/refresh', { refreshToken });
                            const { accessToken: at, refreshToken: rt } = resp.data;
                            setToken(at, rt);
                            const response = await axios.get('/api/v1/account/me');
                            const account = response.data;
                            setAccountInfo(account);
                            dispatch({
                                type: LOGIN,
                                payload: {
                                    isLoggedIn: true,
                                    account
                                }
                            });
                        } catch (error) {
                            setAccountInfo({});
                            dispatch({
                                type: LOGOUT
                            });
                        }
                    } else {
                        setAccountInfo({});
                        dispatch({
                            type: LOGOUT
                        });
                    }
                }
            } catch (err) {
                setAccountInfo({});
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, []);

    const login = async (id: string, password: string) => {
        const response = await axios.post('/api/v1/account/login', { id, password });
        const { accessToken, refreshToken } = response.data;
        // console.log(accessToken, refreshToken);
        setToken(accessToken, refreshToken);
        const resp = await axios.get('/api/v1/account/me');
        const account = resp.data;
        setAccountInfo(account);
        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                account
            }
        });
    };

    const register = async (id: string, name: string, email: string, password: string) => {
        const response = await axios.post('/api/v1/account/signup', {
            id,
            name,
            email,
            password
        });
        const { accessToken, refreshToken } = response.data;
        console.log(accessToken, refreshToken);
        setToken(accessToken, refreshToken);
        // let account = response.data;
        // if (window.localStorage.getItem('account') !== undefined && window.localStorage.getItem('account') !== null) {
        //     const localAccount = window.localStorage.getItem('account');
        //     account = [
        //         ...JSON.parse(localAccount!),
        //         {
        //             id,
        //             name,
        //             email,
        //             password
        //         }
        //     ];
        // }

        // window.localStorage.setItem('account', JSON.stringify(account));
    };

    const logout = () => {
        setAccountInfo({});
        setToken(null, null);
        dispatch({ type: LOGOUT });
    };

    const resetPassword = (email: string) => console.log(email);

    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>
    );
};

export default JWTContext;
