// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TextField,
    Autocomplete,
    Chip,
    Typography,
    Box,
    CardContent,
    InputAdornment,
    Tabs,
    Tab,
    MenuItem
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';

// third-party
import _ from 'lodash';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider, FormikValues } from 'formik';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { gridSpacing } from 'store/constant';

// assets
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import axios from '../../../utils/axios';
import Loader from '../../../ui-component/Loader';
import { Close } from '@mui/icons-material';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import SubCard from 'ui-component/cards/SubCard';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import timezoneList from '../../../utils/timezone';

// constant
const getInitialValues = (config: FormikValues | null) => {
    const newConfig = {
        timeServer: [],
        timezone: '',
        siteCode: 0,
        groupCount: 0,
        dataChannel: 0,
        eventChannel: 0,
        scanChannel: 0,
        cameraChannel: 0,
        totalDurationMs: 0,
        eventDurationMs: 0,
        cfpDurationMs: 0,
        rptDurationMs: 0,
        tagCountLimit: 0,
        modemBitRate: 0,
        modemTxPower: 0,
        rssiLimit: 0,
        extendedSleepTOD: [],
        extendedSleepDuration: [],
        expressModeTOD: [],
        expressModeDuration: [],
        freezerTagTotalMultiplier: 0,
        freezerTagEventDurationMs: 0,
        scanInterval: [],
        // backoffInterval: [],
        fastRoamingThreshold: 0,
        reportCycle: 0,
        bleBeaconTxIntervalMs: 0,
        bleBeaconTxPower: 0,
        evtFrmTxDurationMs: 0,
        lbsOffTOD: [],
        lbsOffDuration: [],
        lbsFrmActiveTOD: [],
        cameraOffTOD: [],
        cameraOffDuration: [],
        tagUnlinkRetentionPeriod: 0,
        tagHistoryRetentionPeriod: 0,
        taskRetentionPeriod: 0,
        pickingTaskRetentionPeriod: 0,
        cameraPictureRetentionPeriod: 0,
        lbsRawRetentionPeriod: 0,
        cameraRfBaudrate: 0
    };

    if (config) {
        return _.merge({}, newConfig, config);
    }

    return newConfig;
};

// ==============================|| CALENDAR EVENT ADD / EDIT / DELETE ||============================== //

interface ConfigSiteFormProps {
    siteId: string;
    service: string;
    handleUpdate: (d: FormikValues) => void;
    onCancel: () => void;
}

function a11yProps(index: number) {
    return {
        id: `config-tab-${index}`,
        'aria-controls': `site-config-tabpanel-${index}`
    };
}

const ConfigSiteForm = ({ siteId, service, handleUpdate, onCancel }: ConfigSiteFormProps) => {
    const theme = useTheme();
    const intl = useIntl();

    const {
        data: config,
        isLoading,
        refetch,
        error
    } = useQuery(['get-site-config', siteId], async () => {
        let res = await axios.get('/api/v1/' + service + '/site/' + siteId + '/config');
        await formik.setValues(res.data || {});

        res.data.extendedSleepTOD = res.data.extendedSleepTOD || [];
        res.data.extendedSleepDuration = res.data.extendedSleepDuration || [];
        res.data.expressModeTOD = res.data.expressModeTOD || [];
        res.data.expressModeDuration = res.data.expressModeDuration || [];
        res.data.lbsOffTOD = res.data.lbsOffTOD || [];
        res.data.lbsOffDuration = res.data.lbsOffDuration || [];
        res.data.lbsFrmActiveTOD = res.data.lbsFrmActiveTOD || [];
        res.data.cameraOffTOD = res.data.cameraOffTOD || [];
        res.data.cameraOffDuration = res.data.cameraOffDuration || [];
        return res.data || {};
    });

    useEffect(() => {
        if (error) {
            console.log(error);
        }
    }, [error]);

    const ConfigSchema = Yup.object().shape({
        timeServer: Yup.array(Yup.string().required()),
        timezone: Yup.string().required(intl.formatMessage({ id: 'this-field-is-required' })),
        siteCode: Yup.number().required(intl.formatMessage({ id: 'this-field-is-required' })),

        groupCount: Yup.number().required(intl.formatMessage({ id: 'this-field-is-required' })),
        // dataChannel: Yup.number().required(intl.formatMessage({ id: 'this-field-is-required' })),
        // eventChannel: Yup.number().required(intl.formatMessage({ id: 'this-field-is-required' })),
        // scanChannel: Yup.number().required(intl.formatMessage({ id: 'this-field-is-required' })),
        totalDurationMs: Yup.number()
            .test(
                'sum-equals-total',
                intl.formatMessage({ id: 'this-value-must-match-the-sum-of-the-following-three-values' }),
                function (value) {
                    const { eventDurationMs, cfpDurationMs, rptDurationMs } = this.parent as {
                        eventDurationMs: number;
                        cfpDurationMs: number;
                        rptDurationMs: number;
                    };
                    return value === eventDurationMs + cfpDurationMs + rptDurationMs;
                }
            )
            .test(
                'is-1000-multiple',
                intl.formatMessage({ id: 'this-value-must-be-a-multiple-of-1000' }),
                (value) => (value || 0) % 1000 === 0
            )
            .required(intl.formatMessage({ id: 'this-field-is-required' })),
        eventDurationMs: Yup.number()
            .test(
                'is-100-multiple',
                intl.formatMessage({ id: 'this-value-must-be-a-multiple-of-100' }),
                (value) => (value || 0) % 100 === 0
            )
            .required(intl.formatMessage({ id: 'this-field-is-required' })),
        cfpDurationMs: Yup.number()
            .test(
                'is-100-multiple',
                intl.formatMessage({ id: 'this-value-must-be-a-multiple-of-100' }),
                (value) => (value || 0) % 100 === 0
            )
            .required(intl.formatMessage({ id: 'this-field-is-required' })),
        rptDurationMs: Yup.number()
            .test(
                'is-100-multiple',
                intl.formatMessage({ id: 'this-value-must-be-a-multiple-of-100' }),
                (value) => (value || 0) % 100 === 0
            )
            .required(intl.formatMessage({ id: 'this-field-is-required' })),
        tagCountLimit: Yup.number().required(intl.formatMessage({ id: 'this-field-is-required' })),
        modemBitRate: Yup.number().required(intl.formatMessage({ id: 'this-field-is-required' })),
        modemTxPower: Yup.number().required(intl.formatMessage({ id: 'this-field-is-required' })),
        rssiLimit: Yup.number().required(intl.formatMessage({ id: 'this-field-is-required' })),
        extendedSleepTOD: Yup.array(Yup.number().required(intl.formatMessage({ id: 'this-field-is-required' }))),
        extendedSleepDuration: Yup.array(Yup.number().required(intl.formatMessage({ id: 'this-field-is-required' }))),
        expressModeTOD: Yup.array(Yup.number().required(intl.formatMessage({ id: 'this-field-is-required' }))),
        expressModeDuration: Yup.array(Yup.number().required(intl.formatMessage({ id: 'this-field-is-required' }))),
        freezerTagTotalMultiplier: Yup.number().required(intl.formatMessage({ id: 'this-field-is-required' })),
        freezerTagEventDurationMs: Yup.number()
            .min(10)
            .max(100)
            .required(intl.formatMessage({ id: 'this-field-is-required' })),
        scanInterval: Yup.array(Yup.number().required(intl.formatMessage({ id: 'this-field-is-required' }))),
        // backoffInterval: Yup.array(Yup.number().required(intl.formatMessage({ id: 'this-field-is-required' }))),
        fastRoamingThreshold: Yup.number()
            .max(0)
            .required(intl.formatMessage({ id: 'this-field-is-required' })),
        reportCycle: Yup.number().required(intl.formatMessage({ id: 'this-field-is-required' })),
        bleBeaconTxIntervalMs: Yup.number().required(intl.formatMessage({ id: 'this-field-is-required' })),
        evtFrmTxDurationMs: Yup.number().required(intl.formatMessage({ id: 'this-field-is-required' })),
        bleBeaconTxPower: Yup.number()
            .min(-40)
            .max(8)
            .required(intl.formatMessage({ id: 'this-field-is-required' })),
        lbsOffTOD: Yup.array(Yup.number().required(intl.formatMessage({ id: 'this-field-is-required' }))),
        lbsOffDuration: Yup.array(Yup.number().required(intl.formatMessage({ id: 'this-field-is-required' }))),
        lbsFrmActiveTOD: Yup.array(Yup.number().required(intl.formatMessage({ id: 'this-field-is-required' }))),
        cameraOffTOD: Yup.array(Yup.number().required(intl.formatMessage({ id: 'this-field-is-required' }))),
        cameraOffDuration: Yup.array(Yup.number().required(intl.formatMessage({ id: 'this-field-is-required' }))),

        tagUnlinkRetentionPeriod: Yup.number().required(intl.formatMessage({ id: 'this-field-is-required' })),
        tagHistoryRetentionPeriod: Yup.number().required(intl.formatMessage({ id: 'this-field-is-required' })),
        taskRetentionPeriod: Yup.number().required(intl.formatMessage({ id: 'this-field-is-required' })),
        pickingTaskRetentionPeriod: Yup.number().required(intl.formatMessage({ id: 'this-field-is-required' }))

        // storeCode: Yup.string()
        //     .max(255)
        //     .strict()
        //     .lowercase('ID must be a lowercase string')
        //     .required('ID is required')
        //     .matches(/^[a-z0-9\s]+$/, 'Only alphanumeric characters are allowed for this field '),
        // name: Yup.string().max(255).required('Name is required')
    });

    const formik = useFormik({
        initialValues: getInitialValues(config),
        validationSchema: ConfigSchema,
        onSubmit: async (values, { resetForm, setSubmitting }) => {
            console.log(values);
            try {
                await handleUpdate(values);
                resetForm();
                onCancel();
                setSubmitting(false);
            } catch (err) {
                console.error(err);
            }
        }
    });

    let [tabIdx, setTabIdx] = useState(0);

    const { values, errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

    if (isLoading) return <Loader />;

    function handleExtendedSleepAddClick() {
        let tod = [...values.extendedSleepTOD, 0];
        let duration = [...values.extendedSleepDuration, 0];
        // @ts-ignore
        formik.setValues({
            ...values,
            extendedSleepTOD: tod as any,
            extendedSleepDuration: duration as any
        });
    }

    function handleExpressModeAddClick() {
        let tod = [...values.expressModeTOD, 0];
        let duration = [...values.expressModeDuration, 0];
        // @ts-ignore
        formik.setValues({
            ...values,
            expressModeTOD: tod as any,
            expressModeDuration: duration as any
        });
    }

    function handleLBSOffAddClick() {
        let tod = [...values.lbsOffTOD, 0];
        let duration = [...values.lbsOffDuration, 0];
        // @ts-ignore
        formik.setValues({
            ...values,
            lbsOffTOD: tod as any,
            lbsOffDuration: duration as any
        });
    }

    function handleCameraOffAddClick() {
        let tod = [...values.cameraOffTOD, 0];
        let duration = [...values.cameraOffDuration, 0];
        // @ts-ignore
        formik.setValues({
            ...values,
            cameraOffTOD: tod as any,
            cameraOffDuration: duration as any
        });
    }

    function handleLBSFrmActiveAddClick() {
        let tod = [...values.lbsFrmActiveTOD, 0];
        // @ts-ignore
        formik.setValues({
            ...values,
            lbsFrmActiveTOD: tod as any
        });
    }

    const handleChangeTabIdx = (event: React.SyntheticEvent, newValue: number) => {
        setTabIdx(newValue);
    };

    return (
        <FormikProvider value={formik}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DialogTitle>
                    <FormattedMessage id="environment-variable-configuration" />
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={gridSpacing} sx={{ mt: -3 }}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">
                                <FormattedMessage id="these-variables-are-used-as-default-values-when-creating-a-new-store" />
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Tabs
                                value={tabIdx}
                                variant="scrollable"
                                indicatorColor="primary"
                                onChange={handleChangeTabIdx}
                                sx={{
                                    minHeight: 0,
                                    '& .MuiTabs-flexContainer': {
                                        border: 'none'
                                    },
                                    '& a': {
                                        minWidth: 10,
                                        py: 1,
                                        px: 1,
                                        mr: 2.2,
                                        color: theme.palette.grey[600],
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    },
                                    '& a.Mui-selected': {
                                        color: theme.palette.primary.dark
                                    }
                                }}
                            >
                                <Tab component={Link} to="#" label={<FormattedMessage id="basic" />} {...a11yProps(0)} />
                                <Tab component={Link} to="#" label={<FormattedMessage id="lbs" />} {...a11yProps(1)} />
                                <Tab component={Link} to="#" label={<FormattedMessage id="camera" />} {...a11yProps(2)} />
                                <Tab component={Link} to="#" label={<FormattedMessage id="data-retention-period" />} {...a11yProps(3)} />
                            </Tabs>
                        </Grid>
                        {tabIdx === 0 && (
                            <>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        id="timeServer"
                                        multiple
                                        fullWidth
                                        autoHighlight
                                        freeSolo
                                        disableCloseOnSelect
                                        options={[]}
                                        value={formik.values.timeServer || []}
                                        onBlur={formik.handleBlur}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue('timeServer', newValue);
                                        }}
                                        renderOption={(props, option) => {
                                            return (
                                                <Box component="li" {...props}>
                                                    {`${intl.formatMessage({ id: 'add' })} "${option}"`}
                                                </Box>
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                label={<FormattedMessage id="time-server" />}
                                                {...params}
                                                error={Boolean(touched.timeServer && errors.timeServer)}
                                                helperText={touched.timeServer && errors.timeServer}
                                            />
                                        )}
                                        renderTags={(value, getTagProps) => {
                                            return value.map((option, index) => {
                                                return (
                                                    <Chip
                                                        {...getTagProps({ index })}
                                                        // variant="combined"
                                                        color={error ? 'error' : 'secondary'}
                                                        label={
                                                            <Typography variant="caption" color="secondary.dark">
                                                                {option}
                                                            </Typography>
                                                        }
                                                        deleteIcon={<Close />}
                                                        size="small"
                                                    />
                                                );
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        disableClearable
                                        // disableCloseOnSelect
                                        options={timezoneList}
                                        value={formik.values.timezone}
                                        onBlur={formik.handleBlur}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue('timezone', newValue);
                                        }}
                                        // renderOption={(props, option) => {
                                        //     return (
                                        //         <Box component="li" {...props}>
                                        //             {`${option}`}
                                        //         </Box>
                                        //     );
                                        // }}
                                        renderInput={(params) => (
                                            <TextField
                                                label={<FormattedMessage id="timezone" />}
                                                {...params}
                                                error={Boolean(touched.timezone && errors.timezone)}
                                                helperText={touched.timezone && errors.timezone}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label={<FormattedMessage id="site-code" />}
                                        type="number"
                                        fullWidth
                                        {...getFieldProps('siteCode')}
                                        error={Boolean(touched.siteCode && errors.siteCode)}
                                        helperText={touched.siteCode && errors.siteCode}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        id="group-count"
                                        type="number"
                                        select
                                        label={intl.formatMessage({ id: 'group-count' })}
                                        {...getFieldProps('groupCount')}
                                        value={formik.values.groupCount}
                                        fullWidth
                                        onChange={formik.handleChange}
                                    >
                                        {Array.apply(null, Array(15)).map((item: any, index: any) => (
                                            <MenuItem value={index + 1} key={'group-count-' + index}>
                                                {index + 1}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                {/*<Grid item xs={12}>*/}
                                {/*    <TextField*/}
                                {/*        label={<FormattedMessage id="data-channel" />}*/}
                                {/*        fullWidth*/}
                                {/*        type="number"*/}
                                {/*        {...getFieldProps('dataChannel')}*/}
                                {/*        error={Boolean(touched.dataChannel && errors.dataChannel)}*/}
                                {/*        helperText={touched.dataChannel && errors.dataChannel}*/}
                                {/*    />*/}
                                {/*</Grid>*/}

                                {/*<Grid item xs={12}>*/}
                                {/*    <TextField*/}
                                {/*        label={<FormattedMessage id="event-channel" />}*/}
                                {/*        fullWidth*/}
                                {/*        type="number"*/}
                                {/*        {...getFieldProps('eventChannel')}*/}
                                {/*        error={Boolean(touched.eventChannel && errors.eventChannel)}*/}
                                {/*        helperText={touched.eventChannel && errors.eventChannel}*/}
                                {/*    />*/}
                                {/*</Grid>*/}

                                {/*<Grid item xs={12}>*/}
                                {/*    <TextField*/}
                                {/*        label={<FormattedMessage id="scan-channel" />}*/}
                                {/*        fullWidth*/}
                                {/*        type="number"*/}
                                {/*        {...getFieldProps('scanChannel')}*/}
                                {/*        error={Boolean(touched.scanChannel && errors.scanChannel)}*/}
                                {/*        helperText={touched.scanChannel && errors.scanChannel}*/}
                                {/*    />*/}
                                {/*</Grid>*/}

                                <Grid item xs={12}>
                                    <TextField
                                        label={<FormattedMessage id="total-duration-ms" />}
                                        fullWidth
                                        type="number"
                                        {...getFieldProps('totalDurationMs')}
                                        error={Boolean(touched.totalDurationMs && errors.totalDurationMs)}
                                        helperText={touched.totalDurationMs && errors.totalDurationMs}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        label={<FormattedMessage id="event-duration-ms" />}
                                        fullWidth
                                        type="number"
                                        {...getFieldProps('eventDurationMs')}
                                        error={Boolean(touched.eventDurationMs && errors.eventDurationMs)}
                                        helperText={touched.eventDurationMs && errors.eventDurationMs}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        label={<FormattedMessage id="cfp-duration-ms" />}
                                        fullWidth
                                        type="number"
                                        {...getFieldProps('cfpDurationMs')}
                                        error={Boolean(touched.cfpDurationMs && errors.cfpDurationMs)}
                                        helperText={touched.cfpDurationMs && errors.cfpDurationMs}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        label={<FormattedMessage id="rpt-duration-ms" />}
                                        fullWidth
                                        type="number"
                                        {...getFieldProps('rptDurationMs')}
                                        error={Boolean(touched.rptDurationMs && errors.rptDurationMs)}
                                        helperText={touched.rptDurationMs && errors.rptDurationMs}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        label={<FormattedMessage id="tag-count-limit" />}
                                        fullWidth
                                        type="number"
                                        {...getFieldProps('tagCountLimit')}
                                        error={Boolean(touched.tagCountLimit && errors.tagCountLimit)}
                                        helperText={touched.tagCountLimit && errors.tagCountLimit}
                                    />
                                </Grid>

                                {/*<Grid item xs={12}>*/}
                                {/*    <TextField*/}
                                {/*        label={<FormattedMessage id="modem-bit-rate" />}*/}
                                {/*        fullWidth*/}
                                {/*        type="number"*/}
                                {/*        {...getFieldProps('modemBitRate')}*/}
                                {/*        error={Boolean(touched.modemBitRate && errors.modemBitRate)}*/}
                                {/*        helperText={touched.modemBitRate && errors.modemBitRate}*/}
                                {/*    />*/}
                                {/*</Grid>*/}

                                <Grid item xs={12}>
                                    <TextField
                                        type="number"
                                        select
                                        label={intl.formatMessage({ id: 'modem-bit-rate' })}
                                        {...getFieldProps('modemBitRate')}
                                        value={formik.values.modemBitRate}
                                        fullWidth
                                        onChange={formik.handleChange}
                                    >
                                        <MenuItem value={1}>1 Mbps</MenuItem>
                                        <MenuItem value={2}>2 Mbps</MenuItem>
                                    </TextField>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        type="number"
                                        select
                                        label={intl.formatMessage({ id: 'modem-tx-power' })}
                                        {...getFieldProps('modemTxPower')}
                                        value={formik.values.modemTxPower}
                                        fullWidth
                                        onChange={formik.handleChange}
                                    >
                                        {Array.apply(null, Array(9)).map((item: any, index: any) => (
                                            <MenuItem value={index} key={'modem-tx-power-' + index}>
                                                {index}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        label={<FormattedMessage id="rssi-limit" />}
                                        fullWidth
                                        type="number"
                                        {...getFieldProps('rssiLimit')}
                                        error={Boolean(touched.rssiLimit && errors.rssiLimit)}
                                        helperText={touched.rssiLimit && errors.rssiLimit}
                                    />
                                </Grid>

                                {/*<Grid item xs={12}>*/}
                                {/*    <TextField*/}
                                {/*        label={<FormattedMessage id="freezer-tag-total-multiplier" />}*/}
                                {/*        fullWidth*/}
                                {/*        type="number"*/}
                                {/*        {...getFieldProps('freezerTagTotalMultiplier')}*/}
                                {/*        error={Boolean(touched.freezerTagTotalMultiplier && errors.freezerTagTotalMultiplier)}*/}
                                {/*        helperText={touched.freezerTagTotalMultiplier && errors.freezerTagTotalMultiplier}*/}
                                {/*    />*/}
                                {/*</Grid>*/}

                                <Grid item xs={12}>
                                    <TextField
                                        type="number"
                                        select
                                        label={intl.formatMessage({ id: 'freezer-tag-total-multiplier' })}
                                        {...getFieldProps('freezerTagTotalMultiplier')}
                                        value={formik.values.freezerTagTotalMultiplier}
                                        fullWidth
                                        onChange={formik.handleChange}
                                    >
                                        {Array.apply(null, Array(7)).map((item: any, index: any) => (
                                            <MenuItem value={index + 1} key={'freezer-tag-interval-' + index}>
                                                {index + 1}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        label={<FormattedMessage id="freezer-tag-event-duration-ms" />}
                                        fullWidth
                                        type="number"
                                        {...getFieldProps('freezerTagEventDurationMs')}
                                        error={Boolean(touched.freezerTagEventDurationMs && errors.freezerTagEventDurationMs)}
                                        helperText={touched.freezerTagEventDurationMs && errors.freezerTagEventDurationMs}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        label={<FormattedMessage id="report-cycle" />}
                                        fullWidth
                                        type="number"
                                        {...getFieldProps('reportCycle')}
                                        error={Boolean(touched.reportCycle && errors.reportCycle)}
                                        helperText={touched.reportCycle && errors.reportCycle}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Autocomplete
                                        id="scanInterval"
                                        multiple
                                        fullWidth
                                        autoHighlight
                                        freeSolo
                                        disableCloseOnSelect
                                        options={[]}
                                        isOptionEqualToValue={() => false}
                                        value={formik.values.scanInterval}
                                        onBlur={formik.handleBlur}
                                        onChange={(event, newValue) => {
                                            for (let i = 0; i < newValue.length; i++) {
                                                // @ts-ignore
                                                newValue[i] = parseInt(newValue[i]);
                                            }
                                            formik.setFieldValue('scanInterval', newValue);
                                        }}
                                        renderOption={(props, option) => {
                                            return (
                                                <Box component="li" {...props}>
                                                    {`${intl.formatMessage({ id: 'add' })} "${option}"`}
                                                </Box>
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                label={<FormattedMessage id="scan-interval" />}
                                                type="number"
                                                {...params}
                                                error={Boolean(touched.scanInterval && errors.scanInterval)}
                                                helperText={touched.scanInterval && errors.scanInterval}
                                            />
                                        )}
                                        renderTags={(value, getTagProps) => {
                                            return value.map((option, index) => {
                                                return (
                                                    <Chip
                                                        {...getTagProps({ index })}
                                                        // variant="combined"
                                                        color={error ? 'error' : 'secondary'}
                                                        label={
                                                            <Typography variant="caption" color="secondary.dark">
                                                                {option}
                                                            </Typography>
                                                        }
                                                        deleteIcon={<Close />}
                                                        size="small"
                                                    />
                                                );
                                            });
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        label={<FormattedMessage id="fast-roaming-threshold" />}
                                        fullWidth
                                        type="number"
                                        {...getFieldProps('fastRoamingThreshold')}
                                        error={Boolean(touched.fastRoamingThreshold && errors.fastRoamingThreshold)}
                                        helperText={touched.fastRoamingThreshold && errors.fastRoamingThreshold}
                                    />
                                </Grid>

                                {/*<Grid item xs={12}>*/}
                                {/*    <Autocomplete*/}
                                {/*        id="backoffInterval"*/}
                                {/*        multiple*/}
                                {/*        fullWidth*/}
                                {/*        autoHighlight*/}
                                {/*        freeSolo*/}
                                {/*        disableCloseOnSelect*/}
                                {/*        options={[]}*/}
                                {/*        isOptionEqualToValue={() => false}*/}
                                {/*        value={formik.values.backoffInterval}*/}
                                {/*        onBlur={formik.handleBlur}*/}
                                {/*        onChange={(event, newValue) => {*/}
                                {/*            for (let i = 0; i < newValue.length; i++) {*/}
                                {/*                // @ts-ignore*/}
                                {/*                newValue[i] = parseInt(newValue[i]);*/}
                                {/*            }*/}
                                {/*            formik.setFieldValue('backoffInterval', newValue);*/}
                                {/*        }}*/}
                                {/*        renderOption={(props, option) => {*/}
                                {/*            return (*/}
                                {/*                <Box component="li" {...props}>*/}
                                {/*                    {`${intl.formatMessage({ id: 'add' })} "${option}"`}*/}
                                {/*                </Box>*/}
                                {/*            );*/}
                                {/*        }}*/}
                                {/*        renderInput={(params) => (*/}
                                {/*            <TextField*/}
                                {/*                label={<FormattedMessage id="backoff-interval" />}*/}
                                {/*                type="number"*/}
                                {/*                {...params}*/}
                                {/*                error={Boolean(touched.backoffInterval && errors.backoffInterval)}*/}
                                {/*                helperText={touched.backoffInterval && errors.backoffInterval}*/}
                                {/*            />*/}
                                {/*        )}*/}
                                {/*        renderTags={(value, getTagProps) => {*/}
                                {/*            return value.map((option, index) => {*/}
                                {/*                return (*/}
                                {/*                    <Chip*/}
                                {/*                        {...getTagProps({ index })}*/}
                                {/*                        // variant="combined"*/}
                                {/*                        color={error ? 'error' : 'secondary'}*/}
                                {/*                        label={*/}
                                {/*                            <Typography variant="caption" color="secondary.dark">*/}
                                {/*                                {option}*/}
                                {/*                            </Typography>*/}
                                {/*                        }*/}
                                {/*                        deleteIcon={<Close />}*/}
                                {/*                        size="small"*/}
                                {/*                    />*/}
                                {/*                );*/}
                                {/*            });*/}
                                {/*        }}*/}
                                {/*    />*/}
                                {/*</Grid>*/}

                                <Grid item xs={12}>
                                    <SubCard
                                        title={<FormattedMessage id="extended-sleep" />}
                                        content={false}
                                        secondary={
                                            <Button color="secondary" variant="contained" onClick={handleExtendedSleepAddClick}>
                                                <AddIcon fontSize="small" sx={{ mr: 0.75 }} />
                                                <FormattedMessage id="new" />
                                            </Button>
                                        }
                                    >
                                        <CardContent sx={{ p: 1, pl: 2 }}>
                                            <Grid container spacing={1} alignItems="center">
                                                {values.extendedSleepTOD &&
                                                    values.extendedSleepTOD.map((tod: any, index: any) => {
                                                        let startTime = new Date(2023, 1, 1, 0, 0, 0);
                                                        let endTime = new Date(2023, 1, 1, 0, 0, 0);
                                                        startTime.setUTCSeconds(tod);
                                                        endTime.setUTCSeconds((values.extendedSleepDuration[index] as number) + tod);

                                                        return (
                                                            <Grid item xs={12} key={'extendedSleepTOD' + index}>
                                                                <Grid container spacing={2} alignItems="center">
                                                                    <Grid item xs={5}>
                                                                        <MobileTimePicker
                                                                            label={<FormattedMessage id="start-time" />}
                                                                            views={['hours', 'minutes']}
                                                                            onChange={(newValue) => {
                                                                                // @ts-ignore
                                                                                let std = new Date(2023, 1, 1, 0, 0, 0);
                                                                                // @ts-ignore
                                                                                let newTOD = (newValue - std) / 1000;
                                                                                if (newTOD < 0) newTOD += 60 * 60 * 24;
                                                                                // @ts-ignore
                                                                                let newDuration = (endTime - newValue) / 1000;
                                                                                if (newDuration < 0) newDuration += 60 * 60 * 24;
                                                                                newDuration = newDuration % (60 * 60 * 24);
                                                                                let exTOD = [...values.extendedSleepTOD];
                                                                                let exDuration = [...values.extendedSleepDuration];

                                                                                // @ts-ignore
                                                                                exTOD[index] = newTOD;
                                                                                // @ts-ignore
                                                                                exDuration[index] = newDuration;
                                                                                // @ts-ignore
                                                                                formik.setValues({
                                                                                    ...values,
                                                                                    extendedSleepTOD: exTOD as any,
                                                                                    extendedSleepDuration: exDuration as any
                                                                                });
                                                                            }}
                                                                            inputFormat="hh:mm a"
                                                                            mask="__:__ _M"
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    fullWidth
                                                                                    margin="normal"
                                                                                    InputProps={{
                                                                                        endAdornment: (
                                                                                            <InputAdornment position="end">
                                                                                                <DateRangeIcon />
                                                                                            </InputAdornment>
                                                                                        )
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            value={startTime}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={5}>
                                                                        <MobileTimePicker
                                                                            label={<FormattedMessage id="end-time" />}
                                                                            views={['hours', 'minutes']}
                                                                            onChange={(newValue) => {
                                                                                // @ts-ignore
                                                                                let newDuration = (newValue - startTime) / 1000;
                                                                                if (newDuration < 0) newDuration += 60 * 60 * 24;
                                                                                newDuration = newDuration % (60 * 60 * 24);
                                                                                let exDuration = [...values.extendedSleepDuration];
                                                                                // @ts-ignore
                                                                                exDuration[index] = newDuration;
                                                                                // @ts-ignore
                                                                                formik.setValues({
                                                                                    ...values,
                                                                                    extendedSleepDuration: exDuration as any
                                                                                });
                                                                            }}
                                                                            inputFormat="hh:mm a"
                                                                            mask="__:__ _M"
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    fullWidth
                                                                                    margin="normal"
                                                                                    InputProps={{
                                                                                        endAdornment: (
                                                                                            <InputAdornment position="end">
                                                                                                <DateRangeIcon />
                                                                                            </InputAdornment>
                                                                                        )
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            value={endTime}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <IconButton
                                                                            onClick={() => {
                                                                                let exTOD = [...values.extendedSleepTOD];
                                                                                let exDuration = [...values.extendedSleepDuration];

                                                                                exTOD.splice(index, 1);
                                                                                exDuration.splice(index, 1);
                                                                                // @ts-ignore
                                                                                formik.setValues({
                                                                                    ...values,
                                                                                    extendedSleepTOD: exTOD as any,
                                                                                    extendedSleepDuration: exDuration as any
                                                                                });
                                                                            }}
                                                                            size="small"
                                                                        >
                                                                            <DeleteTwoToneIcon color="error" />
                                                                        </IconButton>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    })}
                                            </Grid>
                                        </CardContent>
                                    </SubCard>
                                </Grid>

                                {/*<Grid item xs={12}>*/}
                                {/*    <SubCard*/}
                                {/*        title={<FormattedMessage id="express-mode" />}*/}
                                {/*        content={false}*/}
                                {/*        secondary={*/}
                                {/*            <Button color="secondary" variant="contained" onClick={handleExpressModeAddClick}>*/}
                                {/*                <AddIcon fontSize="small" sx={{ mr: 0.75 }} />*/}
                                {/*                <FormattedMessage id="new" />*/}
                                {/*            </Button>*/}
                                {/*        }*/}
                                {/*    >*/}
                                {/*        <CardContent sx={{ p: 1, pl: 2 }}>*/}
                                {/*            <Grid container spacing={1} alignItems="center">*/}
                                {/*                {values.expressModeTOD &&*/}
                                {/*                    values.expressModeTOD.map((tod: any, index: any) => {*/}
                                {/*                        let startTime = new Date(2023, 1, 1, 0, 0, 0);*/}
                                {/*                        let endTime = new Date(2023, 1, 1, 0, 0, 0);*/}
                                {/*                        startTime.setUTCSeconds(tod);*/}
                                {/*                        endTime.setUTCSeconds((values.expressModeDuration[index] as number) + tod);*/}

                                {/*                        return (*/}
                                {/*                            <Grid item xs={12} key={'expressModeTOD' + index}>*/}
                                {/*                                <Grid container spacing={2} alignItems="center">*/}
                                {/*                                    <Grid item xs={5}>*/}
                                {/*                                        <MobileTimePicker*/}
                                {/*                                            label={<FormattedMessage id="start-time" />}*/}
                                {/*                                            views={['hours', 'minutes']}*/}
                                {/*                                            onChange={(newValue) => {*/}
                                {/*                                                // @ts-ignore*/}
                                {/*                                                let std = new Date(2023, 1, 1, 0, 0, 0);*/}
                                {/*                                                // @ts-ignore*/}
                                {/*                                                let newTOD = (newValue - std) / 1000;*/}
                                {/*                                                if (newTOD < 0) newTOD += 60 * 60 * 24;*/}
                                {/*                                                // @ts-ignore*/}
                                {/*                                                let newDuration = (endTime - newValue) / 1000;*/}
                                {/*                                                if (newDuration < 0) newDuration += 60 * 60 * 24;*/}
                                {/*                                                newDuration = newDuration % (60 * 60 * 24);*/}
                                {/*                                                let exTOD = [...values.expressModeTOD];*/}
                                {/*                                                let exDuration = [...values.expressModeDuration];*/}

                                {/*                                                // @ts-ignore*/}
                                {/*                                                exTOD[index] = newTOD;*/}
                                {/*                                                // @ts-ignore*/}
                                {/*                                                exDuration[index] = newDuration;*/}
                                {/*                                                // @ts-ignore*/}
                                {/*                                                formik.setValues({*/}
                                {/*                                                    ...values,*/}
                                {/*                                                    expressModeTOD: exTOD as any,*/}
                                {/*                                                    expressModeDuration: exDuration as any*/}
                                {/*                                                });*/}
                                {/*                                            }}*/}
                                {/*                                            inputFormat="hh:mm a"*/}
                                {/*                                            mask="__:__ _M"*/}
                                {/*                                            renderInput={(params) => (*/}
                                {/*                                                <TextField*/}
                                {/*                                                    {...params}*/}
                                {/*                                                    fullWidth*/}
                                {/*                                                    margin="normal"*/}
                                {/*                                                    InputProps={{*/}
                                {/*                                                        endAdornment: (*/}
                                {/*                                                            <InputAdornment position="end">*/}
                                {/*                                                                <DateRangeIcon />*/}
                                {/*                                                            </InputAdornment>*/}
                                {/*                                                        )*/}
                                {/*                                                    }}*/}
                                {/*                                                />*/}
                                {/*                                            )}*/}
                                {/*                                            value={startTime}*/}
                                {/*                                        />*/}
                                {/*                                    </Grid>*/}
                                {/*                                    <Grid item xs={5}>*/}
                                {/*                                        <MobileTimePicker*/}
                                {/*                                            label={<FormattedMessage id="end-time" />}*/}
                                {/*                                            views={['hours', 'minutes']}*/}
                                {/*                                            onChange={(newValue) => {*/}
                                {/*                                                // @ts-ignore*/}
                                {/*                                                let newDuration = (newValue - startTime) / 1000;*/}
                                {/*                                                if (newDuration < 0) newDuration += 60 * 60 * 24;*/}
                                {/*                                                newDuration = newDuration % (60 * 60 * 24);*/}
                                {/*                                                let exDuration = [...values.expressModeDuration];*/}
                                {/*                                                // @ts-ignore*/}
                                {/*                                                exDuration[index] = newDuration;*/}
                                {/*                                                // @ts-ignore*/}
                                {/*                                                formik.setValues({*/}
                                {/*                                                    ...values,*/}
                                {/*                                                    expressModeDuration: exDuration as any*/}
                                {/*                                                });*/}
                                {/*                                            }}*/}
                                {/*                                            inputFormat="hh:mm a"*/}
                                {/*                                            mask="__:__ _M"*/}
                                {/*                                            renderInput={(params) => (*/}
                                {/*                                                <TextField*/}
                                {/*                                                    {...params}*/}
                                {/*                                                    fullWidth*/}
                                {/*                                                    margin="normal"*/}
                                {/*                                                    InputProps={{*/}
                                {/*                                                        endAdornment: (*/}
                                {/*                                                            <InputAdornment position="end">*/}
                                {/*                                                                <DateRangeIcon />*/}
                                {/*                                                            </InputAdornment>*/}
                                {/*                                                        )*/}
                                {/*                                                    }}*/}
                                {/*                                                />*/}
                                {/*                                            )}*/}
                                {/*                                            value={endTime}*/}
                                {/*                                        />*/}
                                {/*                                    </Grid>*/}
                                {/*                                    <Grid item xs={2}>*/}
                                {/*                                        <IconButton*/}
                                {/*                                            onClick={() => {*/}
                                {/*                                                let exTOD = [...values.expressModeTOD];*/}
                                {/*                                                let exDuration = [...values.expressModeDuration];*/}

                                {/*                                                exTOD.splice(index, 1);*/}
                                {/*                                                exDuration.splice(index, 1);*/}
                                {/*                                                // @ts-ignore*/}
                                {/*                                                formik.setValues({*/}
                                {/*                                                    ...values,*/}
                                {/*                                                    expressModeTOD: exTOD as any,*/}
                                {/*                                                    expressModeDuration: exDuration as any*/}
                                {/*                                                });*/}
                                {/*                                            }}*/}
                                {/*                                            size="small"*/}
                                {/*                                        >*/}
                                {/*                                            <DeleteTwoToneIcon color="error" />*/}
                                {/*                                        </IconButton>*/}
                                {/*                                    </Grid>*/}
                                {/*                                </Grid>*/}
                                {/*                            </Grid>*/}
                                {/*                        );*/}
                                {/*                    })}*/}
                                {/*            </Grid>*/}
                                {/*        </CardContent>*/}
                                {/*    </SubCard>*/}
                                {/*</Grid>*/}
                            </>
                        )}
                        {tabIdx === 1 && (
                            <>
                                <Grid item xs={12}>
                                    <TextField
                                        label={<FormattedMessage id="ble-beacon-tx-interval-ms" />}
                                        fullWidth
                                        type="number"
                                        {...getFieldProps('bleBeaconTxIntervalMs')}
                                        error={Boolean(touched.bleBeaconTxIntervalMs && errors.bleBeaconTxIntervalMs)}
                                        helperText={touched.bleBeaconTxIntervalMs && errors.bleBeaconTxIntervalMs}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label={<FormattedMessage id="ble-beacon-tx-power" />}
                                        fullWidth
                                        type="number"
                                        {...getFieldProps('bleBeaconTxPower')}
                                        error={Boolean(touched.bleBeaconTxPower && errors.bleBeaconTxPower)}
                                        helperText={touched.bleBeaconTxPower && errors.bleBeaconTxPower}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label={<FormattedMessage id="event-frame-tx-duration-ms" />}
                                        fullWidth
                                        type="number"
                                        {...getFieldProps('evtFrmTxDurationMs')}
                                        error={Boolean(touched.evtFrmTxDurationMs && errors.evtFrmTxDurationMs)}
                                        helperText={touched.evtFrmTxDurationMs && errors.evtFrmTxDurationMs}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SubCard
                                        title={<FormattedMessage id="lbs-off" />}
                                        content={false}
                                        secondary={
                                            <Button color="secondary" variant="contained" onClick={handleLBSOffAddClick}>
                                                <AddIcon fontSize="small" sx={{ mr: 0.75 }} />
                                                <FormattedMessage id="new" />
                                            </Button>
                                        }
                                    >
                                        <CardContent sx={{ p: 1, pl: 2 }}>
                                            <Grid container spacing={1} alignItems="center">
                                                {values.lbsOffTOD &&
                                                    values.lbsOffTOD.map((tod: any, index: any) => {
                                                        let startTime = new Date(2023, 1, 1, 0, 0, 0);
                                                        let endTime = new Date(2023, 1, 1, 0, 0, 0);
                                                        startTime.setUTCSeconds(tod);
                                                        endTime.setUTCSeconds((values.lbsOffDuration[index] as number) + tod);

                                                        return (
                                                            <Grid item xs={12} key={'lbsOffTOD' + index}>
                                                                <Grid container spacing={2} alignItems="center">
                                                                    <Grid item xs={5}>
                                                                        <MobileTimePicker
                                                                            label={<FormattedMessage id="start-time" />}
                                                                            views={['hours', 'minutes']}
                                                                            onChange={(newValue) => {
                                                                                // @ts-ignore
                                                                                let std = new Date(2023, 1, 1, 0, 0, 0);
                                                                                // @ts-ignore
                                                                                let newTOD = (newValue - std) / 1000;
                                                                                if (newTOD < 0) newTOD += 60 * 60 * 24;
                                                                                // @ts-ignore
                                                                                let newDuration = (endTime - newValue) / 1000;
                                                                                if (newDuration < 0) newDuration += 60 * 60 * 24;
                                                                                newDuration = newDuration % (60 * 60 * 24);
                                                                                let exTOD = [...values.lbsOffTOD];
                                                                                let exDuration = [...values.lbsOffDuration];

                                                                                // @ts-ignore
                                                                                exTOD[index] = newTOD;
                                                                                // @ts-ignore
                                                                                exDuration[index] = newDuration;
                                                                                // @ts-ignore
                                                                                formik.setValues({
                                                                                    ...values,
                                                                                    lbsOffTOD: exTOD as any,
                                                                                    lbsOffDuration: exDuration as any
                                                                                });
                                                                            }}
                                                                            inputFormat="hh:mm a"
                                                                            mask="__:__ _M"
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    fullWidth
                                                                                    margin="normal"
                                                                                    InputProps={{
                                                                                        endAdornment: (
                                                                                            <InputAdornment position="end">
                                                                                                <DateRangeIcon />
                                                                                            </InputAdornment>
                                                                                        )
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            value={startTime}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={5}>
                                                                        <MobileTimePicker
                                                                            label={<FormattedMessage id="end-time" />}
                                                                            views={['hours', 'minutes']}
                                                                            onChange={(newValue) => {
                                                                                // @ts-ignore
                                                                                let newDuration = (newValue - startTime) / 1000;
                                                                                if (newDuration < 0) newDuration += 60 * 60 * 24;
                                                                                newDuration = newDuration % (60 * 60 * 24);
                                                                                let exDuration = [...values.lbsOffDuration];
                                                                                // @ts-ignore
                                                                                exDuration[index] = newDuration;
                                                                                // @ts-ignore
                                                                                formik.setValues({
                                                                                    ...values,
                                                                                    lbsOffDuration: exDuration as any
                                                                                });
                                                                            }}
                                                                            inputFormat="hh:mm a"
                                                                            mask="__:__ _M"
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    fullWidth
                                                                                    margin="normal"
                                                                                    InputProps={{
                                                                                        endAdornment: (
                                                                                            <InputAdornment position="end">
                                                                                                <DateRangeIcon />
                                                                                            </InputAdornment>
                                                                                        )
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            value={endTime}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <IconButton
                                                                            onClick={() => {
                                                                                let exTOD = [...values.lbsOffTOD];
                                                                                let exDuration = [...values.lbsOffDuration];

                                                                                exTOD.splice(index, 1);
                                                                                exDuration.splice(index, 1);
                                                                                // @ts-ignore
                                                                                formik.setValues({
                                                                                    ...values,
                                                                                    lbsOffTOD: exTOD as any,
                                                                                    lbsOffDuration: exDuration as any
                                                                                });
                                                                            }}
                                                                            size="small"
                                                                        >
                                                                            <DeleteTwoToneIcon color="error" />
                                                                        </IconButton>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    })}
                                            </Grid>
                                        </CardContent>
                                    </SubCard>
                                </Grid>
                                <Grid item xs={12}>
                                    <SubCard
                                        title={<FormattedMessage id="lbs-frame-active" />}
                                        content={false}
                                        secondary={
                                            <Button color="secondary" variant="contained" onClick={handleLBSFrmActiveAddClick}>
                                                <AddIcon fontSize="small" sx={{ mr: 0.75 }} />
                                                <FormattedMessage id="new" />
                                            </Button>
                                        }
                                    >
                                        <CardContent sx={{ p: 1, pl: 2 }}>
                                            <Grid container spacing={1} alignItems="center">
                                                {values.lbsFrmActiveTOD &&
                                                    values.lbsFrmActiveTOD.map((tod: any, index: any) => {
                                                        let startTime = new Date(2023, 1, 1, 0, 0, 0);
                                                        startTime.setUTCSeconds(tod);

                                                        return (
                                                            <Grid item xs={12} key={'lbsOffTOD' + index}>
                                                                <Grid container spacing={2} alignItems="center">
                                                                    <Grid item xs={10}>
                                                                        <MobileTimePicker
                                                                            label={<FormattedMessage id="start-time" />}
                                                                            views={['hours', 'minutes']}
                                                                            onChange={(newValue) => {
                                                                                // @ts-ignore
                                                                                let std = new Date(2023, 1, 1, 0, 0, 0);
                                                                                // @ts-ignore
                                                                                let newTOD = (newValue - std) / 1000;
                                                                                if (newTOD < 0) newTOD += 60 * 60 * 24;
                                                                                let exTOD = [...values.lbsFrmActiveTOD];

                                                                                // @ts-ignore
                                                                                exTOD[index] = newTOD;
                                                                                // @ts-ignore
                                                                                // @ts-ignore
                                                                                formik.setValues({
                                                                                    ...values,
                                                                                    lbsFrmActiveTOD: exTOD as any
                                                                                });
                                                                            }}
                                                                            inputFormat="hh:mm a"
                                                                            mask="__:__ _M"
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    fullWidth
                                                                                    margin="normal"
                                                                                    InputProps={{
                                                                                        endAdornment: (
                                                                                            <InputAdornment position="end">
                                                                                                <DateRangeIcon />
                                                                                            </InputAdornment>
                                                                                        )
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            value={startTime}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <IconButton
                                                                            onClick={() => {
                                                                                let exTOD = [...values.lbsFrmActiveTOD];

                                                                                exTOD.splice(index, 1);
                                                                                // @ts-ignore
                                                                                formik.setValues({
                                                                                    ...values,
                                                                                    lbsFrmActiveTOD: exTOD as any
                                                                                });
                                                                            }}
                                                                            size="small"
                                                                        >
                                                                            <DeleteTwoToneIcon color="error" />
                                                                        </IconButton>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    })}
                                            </Grid>
                                        </CardContent>
                                    </SubCard>
                                </Grid>
                            </>
                        )}
                        {tabIdx === 2 && (
                            <>
                                <Grid item xs={12}>
                                    <TextField
                                        type="number"
                                        select
                                        label={intl.formatMessage({ id: 'camera-rf-baudrate' })}
                                        {...getFieldProps('cameraRfBaudrate')}
                                        value={formik.values.cameraRfBaudrate}
                                        fullWidth
                                        onChange={formik.handleChange}
                                    >
                                        <MenuItem value={1}>1 Mbps</MenuItem>
                                        <MenuItem value={2}>2 Mbps</MenuItem>
                                    </TextField>
                                </Grid>

                                <Grid item xs={12}>
                                    <SubCard
                                        title={<FormattedMessage id="camera-off" />}
                                        content={false}
                                        secondary={
                                            <Button color="secondary" variant="contained" onClick={handleCameraOffAddClick}>
                                                <AddIcon fontSize="small" sx={{ mr: 0.75 }} />
                                                <FormattedMessage id="new" />
                                            </Button>
                                        }
                                    >
                                        <CardContent sx={{ p: 1, pl: 2 }}>
                                            <Grid container spacing={1} alignItems="center">
                                                {values.cameraOffTOD &&
                                                    values.cameraOffTOD.map((tod: any, index: any) => {
                                                        let startTime = new Date(2023, 1, 1, 0, 0, 0);
                                                        let endTime = new Date(2023, 1, 1, 0, 0, 0);
                                                        startTime.setUTCSeconds(tod);
                                                        endTime.setUTCSeconds((values.cameraOffDuration[index] as number) + tod);

                                                        return (
                                                            <Grid item xs={12} key={'cameraOffTOD' + index}>
                                                                <Grid container spacing={2} alignItems="center">
                                                                    <Grid item xs={5}>
                                                                        <MobileTimePicker
                                                                            label={<FormattedMessage id="start-time" />}
                                                                            views={['hours', 'minutes']}
                                                                            onChange={(newValue) => {
                                                                                // @ts-ignore
                                                                                let std = new Date(2023, 1, 1, 0, 0, 0);
                                                                                // @ts-ignore
                                                                                let newTOD = (newValue - std) / 1000;
                                                                                if (newTOD < 0) newTOD += 60 * 60 * 24;
                                                                                // @ts-ignore
                                                                                let newDuration = (endTime - newValue) / 1000;
                                                                                if (newDuration < 0) newDuration += 60 * 60 * 24;
                                                                                newDuration = newDuration % (60 * 60 * 24);
                                                                                let exTOD = [...values.cameraOffTOD];
                                                                                let exDuration = [...values.cameraOffDuration];

                                                                                // @ts-ignore
                                                                                exTOD[index] = newTOD;
                                                                                // @ts-ignore
                                                                                exDuration[index] = newDuration;
                                                                                // @ts-ignore
                                                                                formik.setValues({
                                                                                    ...values,
                                                                                    cameraOffTOD: exTOD as any,
                                                                                    cameraOffDuration: exDuration as any
                                                                                });
                                                                            }}
                                                                            inputFormat="hh:mm a"
                                                                            mask="__:__ _M"
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    fullWidth
                                                                                    margin="normal"
                                                                                    InputProps={{
                                                                                        endAdornment: (
                                                                                            <InputAdornment position="end">
                                                                                                <DateRangeIcon />
                                                                                            </InputAdornment>
                                                                                        )
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            value={startTime}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={5}>
                                                                        <MobileTimePicker
                                                                            label={<FormattedMessage id="end-time" />}
                                                                            views={['hours', 'minutes']}
                                                                            onChange={(newValue) => {
                                                                                // @ts-ignore
                                                                                let newDuration = (newValue - startTime) / 1000;
                                                                                if (newDuration < 0) newDuration += 60 * 60 * 24;
                                                                                newDuration = newDuration % (60 * 60 * 24);
                                                                                let exDuration = [...values.cameraOffDuration];
                                                                                // @ts-ignore
                                                                                exDuration[index] = newDuration;
                                                                                // @ts-ignore
                                                                                formik.setValues({
                                                                                    ...values,
                                                                                    cameraOffDuration: exDuration as any
                                                                                });
                                                                            }}
                                                                            inputFormat="hh:mm a"
                                                                            mask="__:__ _M"
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    fullWidth
                                                                                    margin="normal"
                                                                                    InputProps={{
                                                                                        endAdornment: (
                                                                                            <InputAdornment position="end">
                                                                                                <DateRangeIcon />
                                                                                            </InputAdornment>
                                                                                        )
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            value={endTime}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <IconButton
                                                                            onClick={() => {
                                                                                let exTOD = [...values.cameraOffTOD];
                                                                                let exDuration = [...values.cameraOffDuration];

                                                                                exTOD.splice(index, 1);
                                                                                exDuration.splice(index, 1);
                                                                                // @ts-ignore
                                                                                formik.setValues({
                                                                                    ...values,
                                                                                    cameraOffTOD: exTOD as any,
                                                                                    cameraOffDuration: exDuration as any
                                                                                });
                                                                            }}
                                                                            size="small"
                                                                        >
                                                                            <DeleteTwoToneIcon color="error" />
                                                                        </IconButton>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    })}
                                            </Grid>
                                        </CardContent>
                                    </SubCard>
                                </Grid>
                            </>
                        )}
                        {tabIdx === 3 && (
                            <>
                                <Grid item xs={12}>
                                    <TextField
                                        label={<FormattedMessage id="unlinked-tag-days" />}
                                        fullWidth
                                        type="number"
                                        {...getFieldProps('tagUnlinkRetentionPeriod')}
                                        error={Boolean(touched.tagUnlinkRetentionPeriod && errors.tagUnlinkRetentionPeriod)}
                                        helperText={touched.tagUnlinkRetentionPeriod && errors.tagUnlinkRetentionPeriod}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label={<FormattedMessage id="tag-history-days" />}
                                        fullWidth
                                        type="number"
                                        {...getFieldProps('tagHistoryRetentionPeriod')}
                                        error={Boolean(touched.tagHistoryRetentionPeriod && errors.tagHistoryRetentionPeriod)}
                                        helperText={touched.tagHistoryRetentionPeriod && errors.tagHistoryRetentionPeriod}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label={<FormattedMessage id="task-days" />}
                                        fullWidth
                                        type="number"
                                        {...getFieldProps('taskRetentionPeriod')}
                                        error={Boolean(touched.taskRetentionPeriod && errors.taskRetentionPeriod)}
                                        helperText={touched.taskRetentionPeriod && errors.taskRetentionPeriod}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label={<FormattedMessage id="picking-task-days" />}
                                        fullWidth
                                        type="number"
                                        {...getFieldProps('pickingTaskRetentionPeriod')}
                                        error={Boolean(touched.pickingTaskRetentionPeriod && errors.pickingTaskRetentionPeriod)}
                                        helperText={touched.pickingTaskRetentionPeriod && errors.pickingTaskRetentionPeriod}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label={<FormattedMessage id="camera-picture-days" />}
                                        fullWidth
                                        type="number"
                                        {...getFieldProps('cameraPictureRetentionPeriod')}
                                        error={Boolean(touched.cameraPictureRetentionPeriod && errors.cameraPictureRetentionPeriod)}
                                        helperText={touched.cameraPictureRetentionPeriod && errors.cameraPictureRetentionPeriod}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label={<FormattedMessage id="lbs-raw-days" />}
                                        fullWidth
                                        type="number"
                                        {...getFieldProps('lbsRawRetentionPeriod')}
                                        error={Boolean(touched.lbsRawRetentionPeriod && errors.lbsRawRetentionPeriod)}
                                        helperText={touched.lbsRawRetentionPeriod && errors.lbsRawRetentionPeriod}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </DialogContent>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <DialogActions>
                        <Button type="submit" variant="contained" disabled={isSubmitting}>
                            <FormattedMessage id="save" />
                        </Button>
                        <Button variant="text" color="error" onClick={onCancel}>
                            <FormattedMessage id="cancel" />
                        </Button>
                    </DialogActions>
                </Form>
            </LocalizationProvider>
        </FormikProvider>
    );
};

export default ConfigSiteForm;
