import React, { useState, useEffect } from 'react';

// third-party
import { IntlProvider, MessageFormatElement } from 'react-intl';
import useConfig from 'hooks/useConfig';

// load locales files
const loadLocaleData = (locale: string) => {
    switch (locale) {
        case 'fr':
            return import('utils/locales/fr.json');
        case 'ro':
            return import('utils/locales/ro.json');
        case 'zh':
            return import('utils/locales/zh.json');
        case 'ko':
            return import('utils/locales/ko.json');
        case 'ja':
            return import('utils/locales/ja.json');
        default:
            return import('utils/locales/en.json');
    }
};

// ==============================|| LOCALIZATION ||============================== //

interface LocalsProps {
    children: React.ReactNode;
}

const Locales = ({ children }: LocalsProps) => {
    const { locale } = useConfig();
    const [language, setLanguage] = useState<string>(locale);
    const [messages, setMessages] = useState<Record<string, string> | Record<string, MessageFormatElement[]> | undefined>();

    useEffect(() => {
        var loc = locale;
        if (locale === '') {
            var tmp = (navigator.language || '').slice(0, 2);
            if (['en', 'ko', 'ja'].indexOf(tmp) === -1) {
                loc = 'en';
            } else {
                loc = tmp;
            }
        }
        setLanguage(loc);

        loadLocaleData(loc).then((d: { default: Record<string, string> | Record<string, MessageFormatElement[]> | undefined }) => {
            setMessages(d.default);
        });
    }, [locale]);

    return (
        <>
            {messages && (
                <IntlProvider locale={language} defaultLocale="en" messages={messages}>
                    {children}
                </IntlProvider>
            )}
        </>
    );
};

export default Locales;
