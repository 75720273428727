import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// sample page routing
const Dashboard = Loadable(lazy(() => import('views/pages/dashboard')));
const Store = Loadable(lazy(() => import('views/pages/store')));
const AP = Loadable(lazy(() => import('views/pages/ap')));
const Tag = Loadable(lazy(() => import('views/pages/tag')));
const Anchor = Loadable(lazy(() => import('views/pages/anchor')));
const Camera = Loadable(lazy(() => import('views/pages/camera')));
const Item = Loadable(lazy(() => import('views/pages/item')));
const Task = Loadable(lazy(() => import('views/pages/task')));
const Template = Loadable(lazy(() => import('views/pages/template')));

const SW = Loadable(lazy(() => import('views/pages/config/sw')));
const Hook = Loadable(lazy(() => import('views/pages/config/hook')));
const LED = Loadable(lazy(() => import('views/pages/config/led')));
const ButtonConfig = Loadable(lazy(() => import('views/pages/config/button')));
const Role = Loadable(lazy(() => import('views/pages/config/role')));
const Picking = Loadable(lazy(() => import('views/pages/extra/picking')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Dashboard />
        },
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: '/store',
            element: <Store />
        },
        {
            path: '/ap',
            element: <AP />
        },
        {
            path: '/tag',
            element: <Tag />
        },
        {
            path: '/anchor',
            element: <Anchor />
        },
        {
            path: '/camera',
            element: <Camera />
        },
        {
            path: '/item',
            element: <Item />
        },
        {
            path: '/task',
            element: <Task />
        },
        {
            path: '/sw',
            element: <SW />
        },
        {
            path: '/hook',
            element: <Hook />
        },
        {
            path: '/led',
            element: <LED />
        },
        {
            path: '/button',
            element: <ButtonConfig />
        },
        {
            path: '/role',
            element: <Role />
        },
        {
            path: '/picking',
            element: <Picking />
        },
        {
            path: '/templates',
            element: <Template />
        }
    ]
};

export default MainRoutes;
