/**
 * axios setup to use mock service
 */

import axios from 'axios';
import { LOGIN, LOGOUT } from '../store/actions';
import { KeyedObject } from '../types';
import jwtDecode from 'jwt-decode';
import { dispatch } from '../store';

const axiosServices = axios.create();

const verifyToken: (st: string) => boolean = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded: KeyedObject = jwtDecode(serviceToken);
    // console.log(decoded);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
     */
    // console.log(decoded.exp > Date.now() / 1000);
    return decoded.exp > Date.now() / 1000;
};

const setToken = (accessToken?: string | null, refreshToken?: string | null) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
        localStorage.removeItem('accessToken');
        delete axios.defaults.headers.common.Authorization;
    }

    if (refreshToken) {
        localStorage.setItem('refreshToken', refreshToken);
    } else {
        localStorage.removeItem('refreshToken');
    }
};

// interceptor for http
// axiosServices.interceptors.response.use(
//     (response) => response,
//     async (error) => {
//         if (error.response.status === 401) {
//             try {
//                 const accessToken = window.localStorage.getItem('accessToken');
//                 if (accessToken && verifyToken(accessToken)) {
//                     axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
//                 } else {
//                     const refreshToken = window.localStorage.getItem('refreshToken');
//                     if (refreshToken && verifyToken(refreshToken)) {
//                         const resp = await axios.post('/api/v1/account/refresh', { refreshToken });
//                         const { accessToken: at, refreshToken: rt } = resp.data;
//                         setToken(at, rt);
//                     } else {
//                         dispatch({
//                             type: LOGOUT
//                         });
//                     }
//                 }
//             } catch (err) {
//                 dispatch({
//                     type: LOGOUT
//                 });
//             }
//             return axiosServices(error.config);
//         }
//         return Promise.reject((error.response && error.response.data) || 'Wrong Services');
//     }
// );

axiosServices.interceptors.request.use(
    async (config) => {
        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken && verifyToken(accessToken)) {
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${accessToken}`
            };
        } else {
            const refreshToken = window.localStorage.getItem('refreshToken');
            if (refreshToken && verifyToken(refreshToken)) {
                try {
                    const resp = await axios.post('/api/v1/account/refresh', { refreshToken });
                    const { accessToken: at, refreshToken: rt } = resp.data;
                    setToken(at, rt);
                    config.headers = {
                        ...config.headers,
                        Authorization: `Bearer ${accessToken}`
                    };
                } catch (error) {
                    window.localStorage.removeItem('account');
                    dispatch({
                        type: LOGOUT
                    });
                }
            } else {
                window.localStorage.removeItem('account');
                dispatch({
                    type: LOGOUT
                });
            }
        }
        // console.log(config.headers);
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

export default axiosServices;
