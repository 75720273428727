// project import
import other from './other';
import pages from './pages';
import { pagesForStore } from './pages';
import configuration from './setting';
import extra from './extra';

// types
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    // items: [pages, extra, configuration]
    items: [pages, configuration]
};

export const menuItemsForStore: { items: NavItemType[] } = {
    // items: [pagesForStore, extra]
    items: [pagesForStore]
};

export default menuItems;
