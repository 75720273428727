// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Typography, useMediaQuery, Stack } from '@mui/material';

// project imports
import LAYOUT_CONST from 'constant';
import useConfig from 'hooks/useConfig';
import LogoSection from '../LogoSection';
import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';
import LocalizationSection from './LocalizationSection';

import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';

// assets
import { IconMenu2 } from '@tabler/icons';
import useSessionStorage from '../../../hooks/useSessionStorage';
import React from 'react';
import SiteConfigSection from './SiteConfigSection';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

interface HeaderProps {
    openWebApp: any;
}
const Header = ({ openWebApp }: HeaderProps) => {
    const theme = useTheme();

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const { layout } = useConfig();

    const [routeState] = useSessionStorage('4seils-route', {
        site: '',
        name: '',
        picture: '',
        service: '',
        role: -1
    });

    console.log(routeState);

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                {(layout === LAYOUT_CONST.VERTICAL_LAYOUT || (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd)) && (
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            overflow: 'hidden',
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                            color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                                color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
                            }
                        }}
                        onClick={() => dispatch(openDrawer(!drawerOpen))}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="20px" />
                    </Avatar>
                )}
            </Box>

            <Box
                sx={{
                    ml: 0,
                    mr: 0,
                    [theme.breakpoints.down('md')]: {
                        width: 300,
                        maxWidth: 300,
                        left: 'calc(50% - 150px)'
                    },
                    width: 500,
                    maxWidth: 500,
                    position: 'absolute',
                    left: 'calc(50% - 250px)',
                    display: 'inline-flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                {!routeState.picture && <Avatar sx={{ width: 40, height: 40, mr: 2 }}> {routeState.name[0]} </Avatar>}
                {routeState.picture && <Avatar src={routeState.picture} sx={{ width: 40, height: 40, mr: 2 }}></Avatar>}
                <Stack spacing={0} alignItems="center">
                    <Typography variant="h1">{routeState.name}</Typography>
                    <Typography variant="subtitle2">({routeState.site})</Typography>
                </Stack>
                {(routeState.role === 9999 || routeState.role === 1) && (
                    <Box sx={{ display: { sm: 'block' } }}>
                        <SiteConfigSection />
                    </Box>
                )}
            </Box>

            {/* header search */}
            {/*<SearchSection />*/}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {/* mega-menu */}
            {/*<Box sx={{ display: { xs: 'none', sm: 'block' } }}>*/}
            {/*    <MegaMenuSection />*/}
            {/*</Box>*/}

            {/* live customization & localization */}
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <LocalizationSection />
            </Box>

            {/* notification & profile */}
            {/*<NotificationSection />*/}

            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <ProfileSection openWebApp={openWebApp} />
            </Box>

            {/* mobile header */}
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection openWebApp={openWebApp} />
            </Box>
        </>
    );
};

export default Header;
