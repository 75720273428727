// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Button,
    CardContent,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Stack,
    Switch,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// third-party
import _ from 'lodash';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider, FormikValues } from 'formik';

import { gridSpacing } from 'store/constant';

// assets
import DeleteIcon from '@mui/icons-material/Delete';
import { FileUpload } from '@mui/icons-material';
import React, { useEffect, useRef } from 'react';
import axios from '../../../utils/axios';
import CommandControlForm from '../tag/CommandControlForm';
import AddIcon from '@mui/icons-material/Add';
import SubCard from '../../../ui-component/cards/SubCard';
import { useIntl, FormattedMessage } from 'react-intl';

// constant
const getInitialValues = (site: FormikValues | null) => {
    const newSite = {
        id: '',
        name: '',
        picture: '',
        license: ''
    };

    if (site) {
        return _.merge({}, newSite, site);
    }

    return newSite;
};

// ==============================|| CALENDAR EVENT ADD / EDIT / DELETE ||============================== //

interface AddSiteFormProps {
    site: FormikValues | null;
    handleDelete?: (i: string) => void;
    handleCreate?: (d: FormikValues) => void;
    handleUpdate?: (i: string, d: FormikValues) => void;
    onCancel: () => void;
}

const AddSiteForm = ({ site, handleDelete, handleCreate, handleUpdate, onCancel }: AddSiteFormProps) => {
    const isCreating = !site;
    const intl = useIntl();

    const SiteSchema = Yup.object().shape({
        id: Yup.string()
            .max(255)
            .strict()
            .lowercase(intl.formatMessage({ id: 'id-must-be-a-lowercase-string' }))
            .required(intl.formatMessage({ id: 'this-field-is-required' }))
            .matches(/^[a-z0-9\s]+$/, intl.formatMessage({ id: 'only-alphanumeric-characters-are-allowed-for-this-field' })),
        name: Yup.string()
            .max(255)
            .required(intl.formatMessage({ id: 'this-field-is-required' })),
        license: isCreating
            ? Yup.string()
                  .max(5000)
                  .required(intl.formatMessage({ id: 'this-field-is-required' }))
            : Yup.string()
    });

    const formik = useFormik({
        initialValues: getInitialValues(site),
        validationSchema: SiteSchema,
        onSubmit: async (values, { resetForm, setSubmitting }) => {
            try {
                const data: any = {
                    id: values.id,
                    name: values.name,
                    picture: values.picture
                };

                if (site) {
                    if (handleUpdate != null) {
                        await handleUpdate(site.id, data);
                    }
                } else {
                    data.license = values.license;
                    if (handleCreate != null) {
                        await handleCreate(data);
                    }
                }

                resetForm();
                onCancel();
                setSubmitting(false);
            } catch (error) {
                console.error(error);
            }
        }
    });

    const fileRef = useRef<any>(null);

    // const convertToBase64 = (file) => {
    //     return new Promise((resolve, reject) => {
    //         const fileReader = new FileReader();
    //         fileReader.readAsDataURL(file);
    //         fileReader.onload = () => {
    //             resolve(fileReader.result);
    //         };
    //         fileReader.onerror = (error) => {
    //             reject(error);
    //         };
    //     });
    // };

    const { values, errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

    const handleUploadFile = (e: { currentTarget: any }) => {
        const target = e.currentTarget;

        if (target.files.length === 0) return;
        let file = target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            // @ts-ignore
            formik.setValues({
                ...values,
                picture: reader.result as string
            });
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };

        // axios.post('/api/v1/esl/site/' + routeState.site + '/tag-sw/file', formData).then(async (res) => {
        //     await refetch();
        // });
    };

    // @ts-ignore
    return (
        <FormikProvider value={formik}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DialogTitle>{site ? <FormattedMessage id="edit-site" /> : <FormattedMessage id="create-site" />}</DialogTitle>
                {/*<Divider />*/}
                <DialogContent>
                    <Grid container spacing={gridSpacing} sx={{ mt: 0.25 }}>
                        {!site && (
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label={intl.formatMessage({ id: 'id' })}
                                    {...getFieldProps('id')}
                                    error={Boolean(touched.id && errors.id)}
                                    helperText={touched.id && errors.id}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={intl.formatMessage({ id: 'name' })}
                                {...getFieldProps('name')}
                                error={Boolean(touched.name && errors.name)}
                                helperText={touched.name && errors.name}
                            />
                        </Grid>
                        {isCreating && (
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={3}
                                    label={intl.formatMessage({ id: 'license' })}
                                    {...getFieldProps('license')}
                                    error={Boolean(touched.license && errors.license)}
                                    helperText={touched.license && errors.license}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid container spacing={gridSpacing} sx={{ mt: 0.25 }}>
                        <Grid item xs={12}>
                            <SubCard title={intl.formatMessage({ id: 'picture' })} content={false}>
                                <CardContent>
                                    <Grid container spacing={gridSpacing}>
                                        <Grid item xs={12} justifyContent={'center'} sx={{ display: 'flex', alignItems: 'center' }}>
                                            <TextField
                                                type="file"
                                                id="file-upload"
                                                fullWidth
                                                sx={{ display: 'none' }}
                                                onChange={handleUploadFile}
                                                onClick={(event) => {
                                                    (event.target as any).value = null;
                                                }}
                                                inputRef={fileRef}
                                            />
                                            <InputLabel htmlFor="file-upload" sx={{ cursor: 'pointer' }}>
                                                <IconButton
                                                    onClick={() => {
                                                        fileRef.current.click();
                                                    }}
                                                >
                                                    {!values.picture && <Avatar sx={{ width: 72, height: 72 }}> {values.name[0]} </Avatar>}
                                                    {values.picture && (
                                                        <Avatar src={values.picture} sx={{ width: 72, height: 72 }}></Avatar>
                                                    )}
                                                </IconButton>
                                            </InputLabel>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </SubCard>
                        </Grid>
                    </Grid>
                </DialogContent>

                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <DialogActions>
                        {!isCreating && handleDelete != null && (
                            <Button variant="contained" color="error" onClick={() => handleDelete(site?.id)}>
                                <FormattedMessage id="delete" />
                            </Button>
                        )}
                        <Box sx={{ flexGrow: 1 }} />
                        <Button type="submit" variant="contained" disabled={isSubmitting}>
                            {site ? intl.formatMessage({ id: 'save' }) : intl.formatMessage({ id: 'create' })}
                        </Button>
                        <Button variant="text" color="error" onClick={onCancel}>
                            <FormattedMessage id="cancel" />
                        </Button>
                    </DialogActions>
                </Form>
            </LocalizationProvider>
        </FormikProvider>
    );
};

export default AddSiteForm;
