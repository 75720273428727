import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import HomeLayout from 'layout/HomeLayout';
import Loadable from 'ui-component/Loadable';

// sample page routing
const HomePage = Loadable(lazy(() => import('views/pages/home')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <HomeLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/home',
            element: <HomePage />
        }
    ]
};

export default MainRoutes;
