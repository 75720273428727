// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconCpu, IconSettings, IconDevices, IconSun, IconLock } from '@tabler/icons';
import { NavItemType } from 'types';

// ==============================|| WIDGET MENU ITEMS ||============================== //

const configuration: NavItemType = {
    id: 'configuration',
    title: <FormattedMessage id="configuration" />,
    icon: IconSettings,
    type: 'group',
    children: [
        {
            id: 'led',
            title: <FormattedMessage id="led" />,
            type: 'item',
            url: '/led',
            icon: IconSun,
            breadcrumbs: false
        },
        // {
        //     id: 'button',
        //     title: <FormattedMessage id="button" />,
        //     type: 'item',
        //     url: '/button',
        //     icon: IconToggleLeft,
        //     breadcrumbs: false
        // },
        {
            id: 'sw',
            title: <FormattedMessage id="sw" />,
            type: 'item',
            url: '/sw',
            icon: IconDevices,
            breadcrumbs: false
        },
        {
            id: 'hook',
            title: <FormattedMessage id="hook" />,
            type: 'item',
            url: '/hook',
            icon: IconCpu,
            breadcrumbs: false
        },
        {
            id: 'role',
            title: <FormattedMessage id="role" />,
            type: 'item',
            url: '/role',
            icon: IconLock,
            breadcrumbs: false
        }
    ]
};

export default configuration;
