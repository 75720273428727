// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, Checkbox, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid } from '@mui/material';

import { gridSpacing } from 'store/constant';

// assets
import React from 'react';
import PATTable from './PATTable';
import { FormattedMessage } from 'react-intl';
// ==============================|| CALENDAR EVENT ADD / EDIT / DELETE ||============================== //

interface PATFormProps {
    onCancel: () => void;
}

const PATForm = ({ onCancel }: PATFormProps) => {
    return (
        <>
            <DialogTitle>
                <FormattedMessage id="personal-access-token" />
            </DialogTitle>
            <DialogContent sx={{ p: 0 }}>
                <Grid container spacing={0} sx={{ mt: 0, width: '100%' }}>
                    <PATTable></PATTable>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="text" color="error" onClick={onCancel}>
                    <FormattedMessage id="close" />
                </Button>
            </DialogActions>
        </>
    );
};

export default PATForm;
