// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Button,
    CardContent,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputLabel,
    TextField
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// third-party
import _ from 'lodash';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider, FormikValues } from 'formik';

import { gridSpacing } from 'store/constant';

// assets
import React, { useEffect, useRef } from 'react';
import axios from '../../../utils/axios';
import SubCard from '../../../ui-component/cards/SubCard';
import { useQuery } from 'react-query';
import { REGISTER } from '../../../store/actions';
import { useDispatch } from '../../../store';
import useLocalStorage from '../../../hooks/useLocalStorage';
import Loader from '../../../ui-component/Loader';
import { FormattedMessage, useIntl } from 'react-intl';

// constant
const getInitialValues = (account: FormikValues | null) => {
    const newAccount = {
        id: '',
        name: '',
        email: '',
        picture: '',
        password: '',
        confirmPassword: ''
    };

    if (newAccount) {
        return _.merge({}, newAccount, account);
    }

    return newAccount;
};

// ==============================|| CALENDAR EVENT ADD / EDIT / DELETE ||============================== //

interface EditAccountFormProps {
    onCancel: () => void;
}

const EditAccountForm = ({ onCancel }: EditAccountFormProps) => {
    const dispatch = useDispatch();
    const fileRef = useRef<any>(null);
    const intl = useIntl();

    const {
        data: account,
        isLoading,
        refetch,
        error
    } = useQuery(
        ['get-my-account'],
        async () => {
            let res = await axios.get('/api/v1/account/me');
            await formik.setValues(res.data || {});
            return res.data || {};
        },
        { refetchOnWindowFocus: false }
    );

    const [, setAccountInfo] = useLocalStorage('account', {});

    const AccountSchema = Yup.object().shape({
        id: Yup.string()
            .strict()
            .min(4)
            .max(20)
            .lowercase(intl.formatMessage({ id: 'id-must-be-a-lowercase-string' }))
            .required(intl.formatMessage({ id: 'this-field-is-required' }))
            .matches(/^[a-z0-9\s]+$/, intl.formatMessage({ id: 'only-alphanumeric-characters-are-allowed-for-this-field' })),
        name: Yup.string()
            .min(1)
            .max(64)
            .required(intl.formatMessage({ id: 'this-field-is-required' })),
        email: Yup.string()
            .email(intl.formatMessage({ id: 'invalid-email-format' }))
            .max(255)
            .required(intl.formatMessage({ id: 'this-field-is-required' })),
        password: Yup.string()
            .min(8)
            .max(100)
            .oneOf([Yup.ref('confirmPassword'), null], intl.formatMessage({ id: 'passwords-must-match' })),
        confirmPassword: Yup.string()
            .min(8)
            .max(100)
            .oneOf([Yup.ref('password'), null], intl.formatMessage({ id: 'passwords-must-match' }))
    });

    // const handleUpdate = async (siteID: string, update: FormikValues) => {
    //     try {
    //         await axios.post('/api/v1/' + routeState.service + '/site/' + siteID, update);
    //         const response = await axios.get('/api/v1/' + routeState.service + '/site/' + siteID);
    //         const site = response.data;
    //
    //         setRouteState({
    //             ...routeState,
    //             site: site.id,
    //             name: site.name,
    //             picture: site.picture
    //         });
    //     } catch (error) {}
    //
    //     // setRouteState({ service: modal.service, site: update.id, name: update.name, picture: update.picture });
    //     // navigate('/dashboard', { replace: false });
    //     handleModalClose();
    // };

    const formik = useFormik({
        initialValues: getInitialValues(account),
        validationSchema: AccountSchema,
        onSubmit: async (values, { resetForm, setSubmitting }) => {
            try {
                const data: any = {
                    id: values.id,
                    name: values.name,
                    email: values.email,
                    picture: values.picture
                };

                if (values.password && values.password.length > 0) {
                    data.password = values.password;
                }
                await axios.post('/api/v1/account/update', data);

                const response = await axios.get('/api/v1/account/me');
                const accountData = response.data;
                setAccountInfo(accountData);

                dispatch({
                    type: REGISTER,
                    payload: {
                        accountData
                    }
                });

                resetForm();
                onCancel();
                setSubmitting(false);
            } catch (err: any) {
                console.error(err);
            }
        }
    });

    // const convertToBase64 = (file) => {
    //     return new Promise((resolve, reject) => {
    //         const fileReader = new FileReader();
    //         fileReader.readAsDataURL(file);
    //         fileReader.onload = () => {
    //             resolve(fileReader.result);
    //         };
    //         fileReader.onerror = (error) => {
    //             reject(error);
    //         };
    //     });
    // };

    const { values, errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

    const handleUploadFile = (e: { currentTarget: any }) => {
        const target = e.currentTarget;

        if (target.files.length === 0) return;
        let file = target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            // @ts-ignore
            formik.setValues({
                ...values,
                picture: reader.result as string
            });
        };
        reader.onerror = function (err) {
            console.log('Error: ', err);
        };
    };

    if (isLoading) return <Loader />;

    // @ts-ignore
    return (
        <FormikProvider value={formik}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DialogTitle>
                    <FormattedMessage id="edit-account" />
                </DialogTitle>
                {/*<Divider />*/}
                <DialogContent>
                    <Grid container spacing={gridSpacing} sx={{ mt: 0.25 }}>
                        {/*<Grid item xs={12}>*/}
                        {/*    <TextField*/}
                        {/*        fullWidth*/}
                        {/*        disabled*/}
                        {/*        label="ID"*/}
                        {/*        {...getFieldProps('id')}*/}
                        {/*        error={Boolean(touched.id && errors.id)}*/}
                        {/*        helperText={touched.id && errors.id}*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={intl.formatMessage({ id: 'name' })}
                                {...getFieldProps('name')}
                                error={Boolean(touched.name && errors.name)}
                                helperText={touched.name && errors.name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={intl.formatMessage({ id: 'email' })}
                                {...getFieldProps('email')}
                                error={Boolean(touched.email && errors.email)}
                                helperText={touched.email && errors.email}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={intl.formatMessage({ id: 'password' })}
                                {...getFieldProps('password')}
                                error={Boolean(touched.password && errors.password)}
                                helperText={touched.password && errors.password}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={intl.formatMessage({ id: 'confirm-password' })}
                                {...getFieldProps('confirmPassword')}
                                error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                                helperText={touched.confirmPassword && errors.confirmPassword}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={gridSpacing} sx={{ mt: 0.25 }}>
                        <Grid item xs={12}>
                            <SubCard title={intl.formatMessage({ id: 'picture' })} content={false}>
                                <CardContent>
                                    <Grid container spacing={gridSpacing}>
                                        <Grid item xs={12} justifyContent={'center'} sx={{ display: 'flex', alignItems: 'center' }}>
                                            <TextField
                                                type="file"
                                                id="file-upload"
                                                fullWidth
                                                sx={{
                                                    display: 'none'
                                                }}
                                                onChange={handleUploadFile}
                                                onClick={(event) => {
                                                    (event.target as any).value = null;
                                                }}
                                                inputRef={fileRef}
                                            />
                                            <InputLabel
                                                htmlFor="file-upload"
                                                sx={{
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                <IconButton
                                                    onClick={() => {
                                                        fileRef.current.click();
                                                    }}
                                                >
                                                    {!values.picture && (
                                                        <Avatar
                                                            sx={{
                                                                width: 72,
                                                                height: 72
                                                            }}
                                                        >
                                                            {values.name[0]}
                                                        </Avatar>
                                                    )}
                                                    {values.picture && (
                                                        <Avatar
                                                            src={values.picture}
                                                            sx={{
                                                                width: 72,
                                                                height: 72
                                                            }}
                                                        ></Avatar>
                                                    )}
                                                </IconButton>
                                            </InputLabel>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </SubCard>
                        </Grid>
                    </Grid>
                </DialogContent>

                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <DialogActions>
                        <Box sx={{ flexGrow: 1 }} />
                        <Button type="submit" variant="contained" disabled={isSubmitting}>
                            <FormattedMessage id="save" />
                        </Button>
                        <Button variant="text" color="error" onClick={onCancel}>
                            <FormattedMessage id="cancel" />
                        </Button>
                    </DialogActions>
                </Form>
            </LocalizationProvider>
        </FormikProvider>
    );
};

export default EditAccountForm;
