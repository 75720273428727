// material-ui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

import MobileSection from '../../MainLayout/Header/MobileSection';
import ProfileSection from '../../MainLayout/Header/ProfileSection';
import LocalizationSection from '../../MainLayout/Header/LocalizationSection';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //
import logo from 'assets/images/logo-4seils.svg';
// import logo from 'assets/images/logo-bg-transparent.png';
import React from 'react';

interface HeaderProps {
    openWebApp: any;
}
const Header = ({ openWebApp }: HeaderProps) => {
    const theme = useTheme();

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            ></Box>

            <Box
                sx={{
                    ml: 0,
                    mr: 0,
                    [theme.breakpoints.down('md')]: {
                        width: 300,
                        maxWidth: 300,
                        left: 'calc(50% - 150px)'
                    },
                    width: 500,
                    maxWidth: 500,
                    position: 'absolute',
                    left: 'calc(50% - 250px)',
                    display: 'inline-flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <img src={logo} alt="4seils" height="80" />
            </Box>

            {/* header search */}
            {/*<SearchSection />*/}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {/* mega-menu */}
            {/*<Box sx={{ display: { xs: 'none', sm: 'block' } }}>*/}
            {/*    <MegaMenuSection />*/}
            {/*</Box>*/}

            {/* live customization & localization */}
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <LocalizationSection />
            </Box>

            {/* notification & profile */}
            {/*<NotificationSection />*/}

            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <ProfileSection openWebApp={openWebApp} />
            </Box>

            {/* mobile header */}
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection openWebApp={openWebApp} />
            </Box>
        </>
    );
};

export default Header;
