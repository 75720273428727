// material-ui
import { useTheme, Theme } from '@mui/material/styles';
import {
    Box,
    Button,
    Typography,
    Paper,
    TableHead,
    TableRow,
    TableCell,
    Checkbox,
    TableSortLabel,
    TableContainer,
    Table,
    IconButton,
    TablePagination,
    TableBody,
    Tooltip,
    Toolbar,
    CardContent,
    Grid,
    Dialog,
    SlideProps,
    Slide,
    TableCellProps,
    Stack,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@mui/material';

// project imports
import useSessionStorage from '../../../hooks/useSessionStorage';
import React, { forwardRef, useEffect, useState } from 'react';
import axios from '../../../utils/axios';
import { KeyedObject, EnhancedTableHeadProps, HeadCell } from 'types';
import { useQuery } from 'react-query';
import Loader from '../../../ui-component/Loader';
import RefreshTwoToneIcon from '@mui/icons-material/RefreshTwoTone';
import Chip from 'ui-component/extended/Chip';
import { useDispatch } from '../../../store';
import { format } from 'date-fns';
import useAuth from '../../../hooks/useAuth';
import useLocalStorage from '../../../hooks/useLocalStorage';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import CachedTwoToneIcon from '@mui/icons-material/CachedTwoTone';
import ConfirmationNumberTwoToneIcon from '@mui/icons-material/ConfirmationNumberTwoTone';
import { openSnackbar } from '../../../store/slices/snackbar';
import EditPATForm from './EditPATForm';
import { FormikValues } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';

const defaultList = ['name', 'expiryDate', 'usedAt'];

const viewItemList: any = [
    { key: 'name', text: 'name' },
    { key: 'expiryDate', text: 'expiry-date' },
    { key: 'usedAt', text: 'used-at' }
];

let headCellList: HeadCell[] = [];

// ==============================|| TABLE - HEADER ||============================== //

interface PATTableHeadProps extends TableCellProps {
    theme: Theme;
    // selected: string[];
    // setSelected: any;
    headCells: HeadCell[];
}

function PATTableHead({
    // onSelectAllClick,
    // sort,
    // numSelected,
    // rowCount,
    // onRequestSort,
    theme,
    // selected,
    // setSelected,
    headCells
}: PATTableHeadProps) {
    return (
        <TableHead>
            <TableRow>
                {/*<TableCell key="id" align="center" padding="normal" sortDirection={sort.id !== undefined ? sort.id : false}>*/}
                {/*    <TableSortLabel*/}
                {/*        active={sort.id !== undefined}*/}
                {/*        direction={sort.id}*/}
                {/*        onClick={createSortHandler({ id: 'tagMac', sortable: true, label: 'Tag' })}*/}
                {/*    >*/}
                {/*        Tag*/}
                {/*        {sort.id !== undefined ? (*/}
                {/*            <Box component="span" sx={visuallyHidden}>*/}
                {/*                {sort.id === 'desc' ? 'sorted descending' : 'sorted ascending'}*/}
                {/*            </Box>*/}
                {/*        ) : null}*/}
                {/*    </TableSortLabel>*/}
                {/*</TableCell>*/}
                {headCellList.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="center"
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        // sortDirection={sort[headCell.id] !== undefined ? sort[headCell.id] : false}
                        sx={headCell.style}
                    >
                        <Typography variant="subtitle1" sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}>
                            {headCell.label}
                        </Typography>
                    </TableCell>
                ))}
                <TableCell sortDirection={false} align="center" sx={{ pr: 3 }}>
                    <Typography variant="subtitle1" sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}>
                        <FormattedMessage id="action" />
                    </Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

// ==============================|| TABLE - HEADER TOOLBAR ||============================== //
const Transition = forwardRef((props: SlideProps, ref) => <Slide direction="left" ref={ref} {...props} />);

interface PATTableProps {}

const PATTable = ({}: PATTableProps) => {
    const theme = useTheme();
    const intl = useIntl();

    // const [selected, setSelected] = React.useState<string[]>([]);
    const dispatch = useDispatch();
    const { account } = useAuth();

    const [routeState] = useSessionStorage('4seils-route', {
        site: '',
        name: '',
        picture: '',
        service: '',
        role: -1
    });

    const {
        data: patList,
        isLoading,
        refetch,
        error
    } = useQuery(['get-pat-list'], async () => {
        let res = await axios.get('/api/v1/account/pat');
        return res.data || [];
    });

    const [modal, setModal] = useState<{ data: any; open: boolean; mode: string }>({ data: null, open: false, mode: '' });
    const [confirm, setConfirm] = useState<{ data: any; open: boolean; mode: string; text: string }>({
        data: null,
        open: false,
        mode: '',
        text: ''
    });

    useEffect(() => {
        if (error) {
            console.log(error);
        }
    }, [error]);

    useEffect(() => {
        headCellList = [];
        for (let i = 0; i < viewItemList.length; i++) {
            if (defaultList.indexOf(viewItemList[i].key) > -1) {
                headCellList.push({
                    id: viewItemList[i].key,
                    disablePadding: false,
                    label: intl.formatMessage({ id: viewItemList[i].text }),
                    sortable: false
                });
            }
        }
    }, []);

    // Avoid a layout jump when reaching the last page with empty rows.

    // const emptyRows = Math.max(0, (1 + page) * rowsPerPage - (response?.filtered || 0)) : 0;
    // const emptyRows = 0;

    const getCell = (headCell: any, row: any) => {
        if (headCell.id === 'expiryDate') {
            if (row.expiryDate !== 0) return format(new Date(1000 * row.expiryDate), 'yyyy-MM-dd HH:mm:ss');
            return '-';
        } else if (headCell.id === 'usedAt') {
            if (row.usedAt !== 0) return format(new Date(1000 * row.usedAt), 'yyyy-MM-dd HH:mm:ss');
            return '-';
        }
        return row[headCell.id];
    };

    const handleConfirmOk = async () => {
        if (confirm.mode === 'delete') {
            try {
                await axios.delete('/api/v1/account/pat/' + confirm.data.id);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: intl.formatMessage({ id: 'success' }),
                        anchorOrigin: { vertical: 'top', horizontal: 'right' },
                        variant: 'alert',
                        alert: {
                            color: 'primary'
                        },
                        close: true
                    })
                );
            } catch (err) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: intl.formatMessage({ id: 'failed' }),
                        anchorOrigin: { vertical: 'top', horizontal: 'right' },
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
            }
        } else if (confirm.mode === 'regenerate') {
            let data: any = { id: confirm.data.id };

            try {
                let res = await axios.post('/api/v1/account/pat', data);
                copyClipboard(res.data.token);
                setConfirm({ data: null, open: true, mode: 'token', text: res.data.token });
                return;
            } catch (err) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: intl.formatMessage({ id: 'failed' }),
                        anchorOrigin: { vertical: 'top', horizontal: 'right' },
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
            }
        }
        // let data: any = {};
        // let url: string = '';
        //
        // if (confirm.mode === 'relink') {
        //     if (confirm.data != null) data.target = confirm.data;
        //     else data.all = true;
        //     url = '/api/v1/' + routeState.service + '/site/' + routeState.site + '/store/' + storeCode + '/tag/relink';
        // } else if (confirm.mode === 'unlink') {
        //     data.tag = confirm.data;
        //     url = '/api/v1/' + routeState.service + '/site/' + routeState.site + '/store/' + storeCode + '/tag/unlink';
        // }
        //
        // try {
        //     await axios.post(url, data);
        //     dispatch(
        //         openSnackbar({
        //             open: true,
        //             message: 'Success',
        //             anchorOrigin: { vertical: 'top', horizontal: 'right' },
        //             variant: 'alert',
        //             alert: {
        //                 color: 'primary'
        //             },
        //             close: true
        //         })
        //     );
        // } catch (err) {
        //     dispatch(
        //         openSnackbar({
        //             open: true,
        //             message: 'Failed',
        //             anchorOrigin: { vertical: 'top', horizontal: 'right' },
        //             variant: 'alert',
        //             alert: {
        //                 color: 'error'
        //             },
        //             close: true
        //         })
        //     );
        // }
        //
        setConfirm({ data: null, open: false, mode: '', text: '' });
        await refetch();
    };
    const handleConfirmClose = () => {
        setConfirm({ data: null, open: false, mode: '', text: '' });
    };

    const handleModalClose = () => {
        setModal({ data: null, open: false, mode: '' });
    };

    const handleAddClick = () => {
        setModal({ data: null, open: true, mode: 'edit' });
    };

    const handleEditClick = (data: any) => {
        setModal({ data: data, open: true, mode: 'edit' });
    };
    const handleDeleteClick = (data: any) => {
        setConfirm({ data: data, open: true, mode: 'delete', text: intl.formatMessage({ id: 'pat-delete-warning' }) });
    };

    const handleUpdate = async (update: FormikValues) => {
        // dispatch(updateEvent({ eventId, update }));
        let data: any = { name: update.name };
        let url = '/api/v1/account/pat';
        if (update.id !== 0) {
            url = '/api/v1/account/pat/' + update.id;
        }

        if (update.setExpiryDate) {
            data.expiryDate = update.expiryDate;
        } else {
            data.expiryDate = 0;
        }

        try {
            let res = await axios.post(url, data);
            console.log(res);
            //     handleModalClose();
            //     setSelected([]);
            //
            if (update.id === 0) {
                copyClipboard(res.data.token);
                setConfirm({ data: null, open: true, mode: 'token', text: res.data.token });
            } else {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: intl.formatMessage({ id: 'success' }),
                        anchorOrigin: { vertical: 'top', horizontal: 'right' },
                        variant: 'alert',
                        alert: {
                            color: 'primary'
                        },
                        close: true
                    })
                );
                await refetch();
            }
        } catch (err) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({ id: 'failed' }),
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };

    const handleRegenerateClick = (data: any) => {
        setConfirm({
            data: data,
            open: true,
            mode: 'regenerate',
            text: intl.formatMessage({ id: 'regenerate-description' })
        });
    };

    const copyClipboard = async (text: string) => {
        try {
            await navigator.clipboard.writeText(text);
            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({ id: 'copy-successful' }),
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'primary'
                    },
                    close: true
                })
            );
        } catch (err) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({ id: 'failed' }),
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };

    return (
        <>
            {isLoading && <Loader />}
            <CardContent sx={{ width: '100%', pt: 0 }}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12} sx={{ textAlign: 'right' }}>
                        <Tooltip title={intl.formatMessage({ id: 'new' })} onClick={handleAddClick}>
                            <IconButton size="large">
                                <ConfirmationNumberTwoToneIcon color="primary" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={intl.formatMessage({ id: 'refresh' })} onClick={() => refetch()}>
                            <IconButton size="large">
                                <RefreshTwoToneIcon color="info" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </CardContent>

            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                    <PATTableHead
                        // numSelected={selected.length}
                        // sort={sort}
                        // onRequestSort={handleRequestSort}
                        // rowCount={patList.length || 0}
                        // selected={selected}
                        // setSelected={setSelected}
                        theme={theme}
                        headCells={headCellList}
                    />
                    <TableBody>
                        {/*{stableSort(rows, getComparator(order, orderBy))*/}
                        {/*    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)*/}

                        {!isLoading &&
                            (patList || []).map(function (row: any, index: any) {
                                /** Make sure no display bugs if row isn't an OrderData object */
                                if (typeof row === 'number') return null;
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.tagMac + row.pageIdx}>
                                        {/*<TableCell align="center" component="th" id={labelId} scope="row">*/}
                                        {/*    <Typography*/}
                                        {/*        variant="subtitle1"*/}
                                        {/*        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}*/}
                                        {/*    >*/}
                                        {/*        {row.tagMac}*/}
                                        {/*    </Typography>*/}
                                        {/*</TableCell>*/}

                                        {headCellList.map((headCell) => (
                                            <TableCell key={headCell.id} align="center" padding="normal" sx={{ pt: 0, pb: 0 }}>
                                                {getCell(headCell, row)}
                                            </TableCell>
                                        ))}

                                        <TableCell align="center" sx={{ pr: 3, pt: 0, pb: 0 }}>
                                            <Stack direction="row" justifyContent="center" alignItems="center">
                                                <Tooltip
                                                    title={intl.formatMessage({ id: 'edit' })}
                                                    onClick={() => handleEditClick(patList[index])}
                                                >
                                                    <IconButton size="large">
                                                        <EditTwoToneIcon fontSize="small" color="success" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip
                                                    title={intl.formatMessage({ id: 'regenerate' })}
                                                    onClick={() => handleRegenerateClick(patList[index])}
                                                >
                                                    <IconButton size="large">
                                                        <CachedTwoToneIcon fontSize="small" color="info" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip
                                                    title={intl.formatMessage({ id: 'delete' })}
                                                    onClick={() => handleDeleteClick(patList[index])}
                                                >
                                                    <IconButton size="large">
                                                        <DeleteTwoToneIcon fontSize="small" color="error" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        {/*{emptyRows > 0 && (*/}
                        {/*    <TableRow*/}
                        {/*        style={{*/}
                        {/*            height: 53 * emptyRows*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <TableCell colSpan={6} />*/}
                        {/*    </TableRow>*/}
                        {/*)}*/}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                open={confirm.open}
                onClose={handleConfirmClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ p: 3 }}
            >
                {confirm.open && (
                    <>
                        {confirm.mode !== 'token' && (
                            <DialogTitle id="alert-dialog-title">
                                <FormattedMessage id="are-you-sure" />
                            </DialogTitle>
                        )}
                        {confirm.mode === 'token' && (
                            <DialogTitle id="alert-dialog-title">
                                <FormattedMessage id="make-a-separate-note-of-the-generated-token-the-token-has-been-copied-to-the-clipboard" />
                            </DialogTitle>
                        )}
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {confirm.mode !== 'token' && (
                                    <Typography variant="body2" component="span">
                                        {confirm.text}
                                    </Typography>
                                )}
                                {confirm.mode === 'token' && (
                                    <Typography variant="body2" component="span">
                                        <b>{confirm.text}</b>
                                    </Typography>
                                )}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ pr: 2.5 }}>
                            <Button
                                sx={{ color: theme.palette.error.dark, borderColor: theme.palette.error.dark }}
                                onClick={handleConfirmClose}
                                color="secondary"
                            >
                                <FormattedMessage id="close" />
                            </Button>
                            {confirm.mode !== 'token' && (
                                <Button variant="contained" size="small" onClick={handleConfirmOk} autoFocus>
                                    <FormattedMessage id="ok" />
                                </Button>
                            )}
                        </DialogActions>
                    </>
                )}
            </Dialog>
            <Dialog
                maxWidth="sm"
                fullWidth
                onClose={handleModalClose}
                open={modal.open}
                TransitionComponent={Transition}
                keepMounted
                sx={{
                    '&>div:nth-of-type(3)': {
                        justifyContent: 'flex-end',
                        '&>div': {
                            m: 0,
                            borderRadius: '0px',
                            maxWidth: 450,
                            maxHeight: '100%',
                            minHeight: '100%',
                            [theme.breakpoints.down('md')]: {
                                maxWidth: 'auto',
                                minWidth: 'auto'
                            }
                        }
                    }
                }}
            >
                {/*{modal.open && modal.mode === 'column' && (*/}
                {/*    <ColumnFilterForm*/}
                {/*        onCancel={handleModalClose}*/}
                {/*        viewItemList={viewItemList}*/}
                {/*        setColumnList={setColumnList}*/}
                {/*        columnList={columnList}*/}
                {/*        defaultList={defaultList}*/}
                {/*    />*/}
                {/*)}*/}
                {modal.open && modal.mode === 'edit' && (
                    <EditPATForm data={modal.data} handleUpdate={handleUpdate} onCancel={handleModalClose} />
                )}
                {/*{modal.open && modal.mode === 'link' && (*/}
                {/*    <LinkTagForm storeCode={storeCode} onCancel={handleModalClose} refetch={refetch} />*/}
                {/*)}*/}
                {/*{modal.open && modal.mode === 'control' && (*/}
                {/*    <ControlTagForm storeCode={storeCode} onCancel={handleModalClose} target={modal.data} />*/}
                {/*)}*/}
                {/*{modal.open && modal.mode === 'info' && (*/}
                {/*    <TagInfoForm storeCode={storeCode} onCancel={handleModalClose} target={modal.data} />*/}
                {/*)}*/}

                {/*{modal.open && modal.mode === 'edit' && (*/}
                {/*    <EditAPForm*/}
                {/*        siteId={modal.ap.siteId}*/}
                {/*        mac={modal.ap.id}*/}
                {/*        handleUpdate={handleUpdateAPConfig}*/}
                {/*        onCancel={handleModalClose}*/}
                {/*    />*/}
                {/*)}*/}
            </Dialog>
        </>
    );
};

export default PATTable;
